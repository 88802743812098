import React from 'react';

import { LoadingContainer } from '../LoadingContainer';

import './pageLoadingContainer.scss';

// page loading
export default function PageLoadingContainer({ style = '' }) {

    return (
        <div id="page-loading-container" className={style}>
            <LoadingContainer>
                <h1 className='loading-text'>Loading...</h1>
            </LoadingContainer>
        </div>
    );
}
