import React, { useState, useEffect, useContext } from 'react';

import LoadingContext from '../../context/Loading/Loading';

import { getPromoStats } from '../../utilities/api';
import { PageLoadingContainer } from '../PageLoadingContainer';

import { TrackingLinks } from './TrackingLinks';

export default function TrackingLinksWrapper({ eventId }) {

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const [promos, setPromos] = useState([])

    const [isRemoving, setIsRemoving] = useState(false)

    useEffect(() => {
        showLoading();
        getPromoStats(eventId)
            .then((res) => {
                setPromos(res.data);
                hideLoading()
            })
            .catch((err) => {
                console.error(err)
                hideLoading()
            })

    }, [eventId])

    const removeTrackingLink = () => {
        return new Promise((resolve, reject) => {
            setIsRemoving(true)
            setIsRemoving(false)
            resolve();
        })
    }

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <>
                    <section className='max-width-wrapper'>
                        <header className='section-header'>
                            <div className="section-header" >
                                <div className="section-heading">
                                    <h1>Tracking links</h1>
                                </div>
                                <p className='section-header-desc'>Use custom links to track the success of your promotional emails, promoter partners, and more</p>
                            </div>
                        </header>
                        <TrackingLinks promos={promos} remove={removeTrackingLink} isRemoving={isRemoving} />
                    </section>
                </>
            )}
        </>
    );
}


