import React from 'react';

import { removeHyphens, formatCurrency, formatShortDate, formatDateTime } from '../../../../utilities/helpers';

import Stack from 'react-bootstrap/Stack';
import Badge from 'react-bootstrap/Badge';
import moment from 'moment';
import { EditDeleteDropdown } from '../../../EditDeleteDropdown';

export default function Ticket({ ticket, handleShow }) {

    const ticketStatusColors = [
        {
            'on_sale': 'success',
            'scheduled': 'primary',
            'sale_ended': 'dark',
            'sold_out': 'warning'
        }
    ]

    const formatTicketStatus = (status) => {
        return removeHyphens(status)
    }

    const getTicketStatusColor = (status) => {
        return ticketStatusColors.map((obj) => obj[status])
    }

    const isActive = ticket => {
        return (!ticket?.soldOut && ticket?.status !== 'sale_ended')
    }

    const getText = (ticket) => {
        const { status } = ticket
        switch (status) {
            case 'scheduled':
                return 'Starts'

            default:
                return "Ends"
        }
    }

    return (
        <Stack direction='horizontal' as="li" className='list-item ticket-row'>
            <Stack>
                <h2 className='ticket-name normal text-truncate'>{ticket?.name}</h2>
                <Stack direction='horizontal' gap={2}>
                    <Badge bg={getTicketStatusColor(ticket?.status)} className='text-uppercase'>{formatTicketStatus(ticket?.status)}</Badge>
                    {(isActive(ticket)) && (
                        <span className='text-muted small'>{getText(ticket)} {formatShortDate(moment(ticket?.desc), 'dateOnly')} at {formatDateTime(moment(ticket?.desc).utcOffset(ticket?.timezone?.offset, false), 'timeOnly')} {ticket?.timezone?.abbr}</span>
                    )}
                </Stack>
            </Stack>
            <Stack>
                <span>{ticket?.sold}/{ticket?.count}</span>
            </Stack>
            <Stack>
                <span>{ticket?.price && ticket?.price > 0 ? formatCurrency(ticket?.price) : 'Free'}</span>
            </Stack>
            <EditDeleteDropdown canDelete={isActive(ticket)} handleShow={handleShow} link='edit' search={`?group=${encodeURIComponent((ticket?.ticketGroup))}`} />
        </Stack>
    );
}
