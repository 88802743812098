import React from 'react';

export default function EyeIconSlash() {

    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_8391_14821)">
                <path d="M5.05032 14.95C6.47483 16.0358 8.2094 16.6374 10.0003 16.6667C15.8337 16.6667 19.167 10 19.167 10C18.1304 8.06825 16.6927 6.38051 14.9503 5.05M11.7503 3.53333C11.1767 3.39907 10.5894 3.33195 10.0003 3.33333C4.16699 3.33333 0.833658 10 0.833658 10C1.33951 10.9463 1.94278 11.8373 2.63366 12.6583M8.23366 11.7667C8.46253 12.0123 8.73853 12.2093 9.0452 12.3459C9.35186 12.4826 9.68291 12.556 10.0186 12.562C10.3543 12.5679 10.6877 12.5061 10.999 12.3804C11.3103 12.2547 11.5931 12.0675 11.8305 11.8301C12.0678 11.5927 12.255 11.31 12.3807 10.9987C12.5065 10.6874 12.5682 10.3539 12.5623 10.0183C12.5564 9.68258 12.4829 9.35154 12.3463 9.04487C12.2096 8.73821 12.0126 8.46221 11.767 8.23333" stroke="#777E90" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M18 2L2 18" stroke="#777E90" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_8391_14821">
                    <rect width="20" height="20" fill="white" transform="matrix(-1 0 0 1 20 0)" />
                </clipPath>
            </defs>
        </svg>
    );
}
