import React from 'react';
import { useSearchParams } from "react-router-dom";

import { RegisterWrapper } from "../../components"

export default function RegisterPage() {

    const [searchParams] = useSearchParams();

    const code = searchParams.get("code");

    return (
        <div className="spacer-md">
            <RegisterWrapper code={code} />
        </div>
    )
}