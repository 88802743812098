import React, { useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { DateInputWrapper } from '../../DateInputWrapper';
import { TimeInputWrapper } from '../../TimeInputWrapper';

export default function DateTime({ event, isReadOnly, handleChange, setEventStart, eventStart, setEventEnd, eventEnd, setDoorsOpen, doorsOpen, setError, error, timeError }) {

    useEffect(() => {

    }, [event])

    return (
        <Form>
            <fieldset className='form-group'>
                <legend>Event starts</legend>
                <Row>
                    <Col>
                        <DateInputWrapper
                            label="Event start"
                            id="event-start"
                            isReadOnly={isReadOnly}
                            setDate={setEventStart}
                            selectedDate={eventStart}
                            startDate={eventStart}
                            endDate={eventEnd}
                            displayEndDate={true}
                            setError={setError}
                            error={error}
                        />
                    </Col>
                    <Col>
                        <TimeInputWrapper
                            label="Start time"
                            id="event-start-time"
                            isReadOnly={isReadOnly}
                            setDate={setEventStart}
                            selectedDate={eventStart}
                        />
                    </Col>
                </Row>
                <div className="mt-3">
                    <Form.Check type='checkbox' className="form-check-display" id="event-start-display-checkbox">
                        <Form.Check.Input
                            name="display_start_time"
                            type='checkbox'
                            disabled={isReadOnly}
                            checked={event?.display_start_time}
                            onChange={(e) => { handleChange(e, e.target.checked) }} />
                        <div>
                            <Form.Check.Label id="event-start-display-checkbox">Display start time</Form.Check.Label>
                            <small className='form-check-text'>
                                The start time of your event will be displayed to attendees
                            </small>
                        </div>
                    </Form.Check>
                </div>
            </fieldset>
            <fieldset className='form-group'>
                <legend>Doors open</legend>
                <Row>
                    <Col>
                        <TimeInputWrapper
                            width="50"
                            label="time"
                            id="doors-open-time"
                            isReadOnly={isReadOnly}
                            setDate={setDoorsOpen}
                            selectedDate={doorsOpen}
                            error={timeError}
                        />
                    </Col>
                </Row>
            </fieldset>

            <fieldset className='form-group'>
                <legend>Event ends</legend>
                <Row>
                    <Col>
                        <DateInputWrapper
                            label="Event end"
                            id="event-end"
                            isReadOnly={isReadOnly}
                            setDate={setEventEnd}
                            selectedDate={eventEnd}
                            startDate={eventStart}
                            endDate={eventEnd}
                            setError={setError}
                            error={error}
                            displayEndDate={true}
                        />
                    </Col>
                    <Col>
                        <TimeInputWrapper
                            label="End time"
                            id="event-end-time"
                            isReadOnly={isReadOnly}
                            setDate={setEventEnd}
                            selectedDate={eventEnd}
                        />
                    </Col>
                </Row>
                <div className="mt-3">
                    <Form.Check type='checkbox' className="form-check-display" id="event-end-display-checkbox">
                        <Form.Check.Input
                            name="hide_end_date"
                            type='checkbox'
                            disabled={isReadOnly}
                            checked={!event?.hide_end_date}
                            onChange={(e) => { handleChange(e, !e.target.checked) }} />
                        <div>
                            <Form.Check.Label id="event-end-display-checkbox">Display end time</Form.Check.Label>
                            <small className='form-check-text'>
                                The end time of your event will be displayed to attendees
                            </small>
                        </div>
                    </Form.Check>
                </div>
            </fieldset>
        </Form>
    );
}
