import React from 'react';

import { HomeWrapper } from '../../components';

export default function HomePage() {

    return (
        <div className="spacer-md">
            <HomeWrapper />
        </div>
    );
}
