import { useParams } from 'react-router-dom';

import { AttendeesWrapper } from '../../components'

export default function AttendeesPage() {

    const { uuid } = useParams();

    return (
        <AttendeesWrapper eventId={uuid} />
    )
}