import React from 'react';

import './noPermissionsContainer.scss';

export default function NoPermissionsContainer() {
    return (
        <div className='permissions'>
            <h1>You don't have permission to view this page </h1>
        </div>
    );
}
