import React, { useEffect, useState, useContext } from 'react';

import LoadingContext from '../../context/Loading/Loading';
import AuthService from '../../utilities/services/auth.service';
import UserContext from '../../context/User/User';

import { getW9, createW9 } from '../../utilities/api';
import { checkPermission } from '../../utilities/helpers';

import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import { Spinner } from '../LoadingContainer/Spinner';
import { PageLoadingContainer } from '../PageLoadingContainer';
import { TaxCard } from "./TaxCard";
import { TaxWrapper } from '../TaxWrapper';
import { BackButton } from "../BackButton";
import { NoPermissionsContainer } from '../NoPermissionsContainer';

export default function TaxStatusWrapper() {

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const { orgPermissions } = useContext(UserContext)

    const { getPermissions } = AuthService;

    const [hasPermission, setHasPermission] = useState(true);

    const [taxStatus, setTaxStatus] = useState()

    const [showForm, setShowForm] = useState(false)

    const [step, setStep] = useState();

    const [isSaving, setIsSaving] = useState(false)

    useEffect(() => {
        if (orgPermissions?.length > 0) setHasPermission(checkPermission(orgPermissions, getPermissions(), 7));

    }, [orgPermissions])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [step])

    useEffect(() => {
        showLoading()
        myW9()
    }, [])

    const myW9 = () => {
        getW9()
            .then((res) => {
                setTaxStatus(res.data)
                hideLoading()
            })
            .catch((err) => {
                console.error(err)
                hideLoading()
            })
    }

    const handleGoBack = () => setStep(step - 1)

    const handleStep = () => setStep(step + 1)

    const handleClick = () => {
        if (step === 3) {

            // save tax status
            setIsSaving(true)
            createW9({ data: taxStatus })
                .then((res) => {
                    setIsSaving(false)

                    if (!isSaving) {
                        setShowForm(false)
                    }
                })
                .catch((err) => {
                    setIsSaving(false)
                    console.error(err)
                })
        }
        else {
            handleStep();
        }
    }

    const getText = () => {
        if (step === 3) {
            if (isSaving) {
                return <Spinner />
            }
            return 'Submit'
        } else {
            return 'Next'
        }
    }

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <div className='position-relative'>
                    <section className={`wrapper ${!hasPermission ? 'overlay' : ''}`}>
                        <header className="section-header">
                            <div className="section-heading section-heading--secondary">
                                <h1>Tax status</h1>
                            </div>
                            <p className='section-header-desc'>Fill out a W-9 form in order to receive your funds</p>
                        </header>
                        <Card body className='card--sm'>
                            {!showForm ? (
                                <>
                                    {taxStatus ? (
                                        <TaxCard show={setShowForm} account={taxStatus} />
                                    ) : (
                                        <Button size="lg" className="btn-tax w-100" onClick={() => setShowForm(true)}>Fill out W9 form</Button>
                                    )}
                                </>
                            ) : (
                                <TaxWrapper step={step} setStep={setStep} getTaxDetails={setTaxStatus} account={taxStatus} />
                            )}
                        </Card>
                        {showForm && (
                            <Stack direction="horizontal" className="btn-group-flex">
                                {step !== 1 && (
                                    <BackButton handleGoBack={handleGoBack} size="lg" />
                                )}
                                <Button className={`btn-width-md ${!isSaving ? 'btn-next' : ''} `} size="lg" disabled={isSaving} onClick={handleClick}>{getText()}</Button>
                            </Stack>
                        )}
                    </section>
                    {!hasPermission && (
                        <NoPermissionsContainer />
                    )}
                </div>
            )}
        </>
    );
}
