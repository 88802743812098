import React from 'react';

import Form from 'react-bootstrap/Form';

export default function TrackingLink({ link, handleChange, isValid, validInputs }) {

    return (
        <Form>
            <Form.Group className="form-group" controlId='name'>
                <Form.Label>Name of your tracking link</Form.Label>
                <Form.Control type="text" name="name" placeholder="Enter name"
                    value={link.name} onChange={handleChange}
                    onBlur={validInputs}
                    pattern='^[a-zA-z0-9]+$'
                    required
                    className={`${(link.name && !isValid) ? 'error-border' : ''}`}
                />
                <Form.Text className={`${(link.name && !isValid) ? 'text-danger' : ''}`}>Only letters and numbers are allowed. (Examples: presalediscount, fanclubonly, fc143342, etc.)</Form.Text>
            </Form.Group>
        </Form>
    );
}
