import React, { useEffect, useState, useRef } from 'react';

import Stack from 'react-bootstrap/Stack';
import Form from 'react-bootstrap/Form';

import { TimeInput } from './TimeInput';

export default function TimeInputWrapper({ label, id, setDate, selectedDate, width, size, error, isReadOnly = false }) {

    const timeRef = useRef();

    // temporary state to store date in case of manually entering 
    const [chosenDate, setChosenDate] = useState(selectedDate)

    useEffect(() => {
        setDate(chosenDate)
    }, [chosenDate])

    const filterPassedTime = (time) => {
        // the below was disabled as it wasn't allowing admins to select date on edit
        // const currentDate = new Date();
        // const selectedDate = new Date(time);
        // return currentDate.getTime() < selectedDate.getTime();

        return time
    };

    return (
        <Form.Group>
            <div className={`date-picker-card time-picker-card ${size ? `date-picker-card-${size}` : ''} ${width ? `w-${width}` : ''} ${error ? 'error-border' : ''}`} ref={timeRef} >
                <Stack>
                    {label && (<Form.Label htmlFor={id}>{label}</Form.Label>)}
                    <TimeInput id={id} selectedDate={selectedDate} setChosenDate={setChosenDate} filterPassedTime={filterPassedTime} reference={timeRef} isReadOnly={isReadOnly} />
                </Stack>
            </div>
            {error && (
                <Form.Text id={id} className="error">Doors open time must be before event start time</Form.Text>
            )}
        </Form.Group>
    );
}
