import React, { useEffect, useRef } from 'react';

import Stack from 'react-bootstrap/Stack';
import Form from 'react-bootstrap/Form';

import { DateInput } from './DateInput';

export default function DateInputWrapper({ label, id, setDate, selectedDate, startDate, endDate, setError, error, size, displayEndDate = false, maxDate, isReadOnly = false }) {

    const dateRef = useRef();

    // start and end date validation only if end date is displayed 
    useEffect(() => {
        if (displayEndDate) {
            setError(endDate.getTime() <= startDate.getTime())
        } else {
            if (setError) {
                setError(false);
            }
        }
    }, [startDate, endDate, displayEndDate])

    return (
        <Form.Group>
            <div className={`date-picker-card day-picker-card ${size ? `date-picker-card-${size}` : ''} ${error ? 'error-border' : ''}`} ref={dateRef}>
                <Stack>
                    {label && (<Form.Label htmlFor={id} className={`${error ? 'error' : ''}`}>{label}</Form.Label>)}
                    <DateInput id={id} setDate={setDate} selectedDate={selectedDate} startDate={startDate} endDate={endDate} maxDate={maxDate} reference={dateRef} isReadOnly={isReadOnly} />
                </Stack>
            </div>
            {error && (
                <Form.Text className='error'>End date must be after start date</Form.Text>
            )}
        </Form.Group>
    );
}
