import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import LoadingContext from '../../context/Loading/Loading';

import { uploadGuestList, getEventTicketTypes } from '../../utilities/api';

import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import { Spinner } from '../LoadingContainer/Spinner'
import { PageLoadingContainer } from '../PageLoadingContainer';

export default function GuestInformationWrapper({ eventId }) {

    const navigate = useNavigate();

    const fileReader = new FileReader();

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const [show, setShow] = useState(false);

    const [ticketTypes, setTicketTypes] = useState()

    const [valid, setValid] = useState(false)

    const [isSaving, setIsSaving] = useState(false)

    const [file, setFile] = useState(null)

    // demo purposes - will come from database - display error is ticket quantity is more than event max ticket quantity 
    const maxTicketQuantity = 50;

    const [guest, setGuest] = useState({
        ticketType: ''
    })

    useEffect(() => {
        if (guest.ticketType && file) return setValid(true)
        setValid(false)
    }, [guest.ticketType, file])

    useEffect(() => {
        showLoading()
        getEventTicketTypes(eventId)
            .then((res) => {
                setTicketTypes(res.data)
                hideLoading()
            })
            .catch((err) => {
                console.log(err)
                hideLoading()
            })
    }, [])

    const handleChange = e => {
        setGuest({ ...guest, [e.target.name]: e.target.value })
    }

    const handleClose = () => {
        setShow(false)
        navigate(-1)
    };

    const handleShow = () => navigate(-1);

    const handleSave = () => {
        setIsSaving(true)
        let formData = new FormData();

        formData.append('csv', file);
        formData.append('eventUUID', eventId);
        formData.append('ticketType', guest.ticketType);

        uploadGuestList(formData)
            .then((res) => {
                setIsSaving(false)
                setShow(true)
            })
            .catch((err) => console.error(err))
    }

    const submitFile = (e) => {
        setFile(e.target.files[0])
    }

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <section className='wrapper'>
                    <section>
                        <header className="section-header section-heading">
                            <h1>Guest Upload</h1>
                        </header>
                        <Button className="mb-4" variant="outline-light" size="lg" href="https://blocktickets.nyc3.cdn.digitaloceanspaces.com/csv's/guestUploadTemplate.csv">Download CSV Template</Button>
                        <Card body>
                            <Form>
                                <Form.Group controlId="formFileLg" className="mb-3">
                                    <Form.Label>Upload csv file</Form.Label>
                                    <Form.Control type="file" size="lg" onChange={(e) => submitFile(e)} accept={".csv"} />
                                </Form.Group>
                                <Form.Group controlId='ticketType'>
                                    <Form.Label>Ticket type</Form.Label>
                                    <Form.Select name="ticketType" onChange={handleChange} value={guest.ticketType}>
                                        <option>Select Ticket Type</option>
                                        {ticketTypes?.map((option, index) => (
                                            <option key={index} value={option}>{option}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Form>
                        </Card>
                    </section>
                    <Stack direction="horizontal" className="btn-group-flex">
                        <Button variant="outline-light" size="lg" onClick={() => handleShow()}>Cancel</Button>
                        <Button size="lg" className="btn-width-md" onClick={handleSave} disabled={!valid || isSaving}>
                            {isSaving ? (
                                <Spinner />
                            ) :
                                "Upload file"
                            }
                        </Button>
                    </Stack>
                </section>
            )}

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Processing File</Modal.Title>
                </Modal.Header>
                <Modal.Body>Woohoo, your guests are being notified via email that they're on the list! Please allow time for the Guest List to fully update for larger files.</Modal.Body>
                <Modal.Footer>
                    <Button size="lg" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
