import React from 'react';

import { exportData } from "../../utilities/helpers";

import Stack from 'react-bootstrap/Stack'
import Button from 'react-bootstrap/Button';

export default function ExportBtn({ data, exportedData, capacity }) {
    return (
        <Button variant="outline-light" className='justify-content-center align-items-center btn-download' onClick={() => exportData(data, exportedData, capacity)}>
            <Stack className='justify-content-between align-items-start'>
                <span className="text-muted d-block caption fw-normal">Export</span>
                <span className='fw-medium mb-1'>Excel</span>
            </Stack>
        </Button>
    );
}
