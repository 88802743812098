import React, { useState, useEffect } from 'react';

import Stack from 'react-bootstrap/Stack';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { InfoIcon } from '../../InfoIcon';
import { DateInputWrapper } from '../../DateInputWrapper';
import { TimeInputWrapper } from '../../TimeInputWrapper';
import { TicketBreakdownModal } from './TicketBreakdownModal';

import lock from '../../../assets/icons/lock.svg'

export default function CreateTicket({ eventEnd, type, isEdit, isReadOnly, handleChange, handleValid, ticket, setSalesStart, salesStart, setSalesEnd, salesEnd, setHasError, hasError, errors, fees, taxRates }) {

    const isPaid = type === 'paid';

    const [show, setShow] = useState(false)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    let user = localStorage.getItem('user')
    user = JSON.parse(user).user
    

    useEffect(() => {
        // listens for changes on ticket
    }, [ticket])

    return (
        <>
            <Form>
                {isEdit && (
                    <div className="form-group">
                        <Form.Check type='checkbox' className="form-check-display" id="hide-ticket-checkbox">
                            <Form.Check.Input
                                name="hideTicket"
                                type='checkbox'
                                disabled={isReadOnly}
                                checked={ticket.hideTicket}
                                onChange={(e) => { handleChange(e, e.target.checked) }} />
                            <div>
                                <Form.Check.Label id="hide-ticket-checkbox">Hide ticket</Form.Check.Label>
                                <small className='form-check-text'>
                                    Once you hide the ticket it will not be available for purchase
                                </small>
                            </div>
                        </Form.Check>
                    </div>
                )}
                {/* Temporarily added to remove sales tax from tickets */}
                {user.id === 2 && (
                    <div className="form-group">
                        <Form.Check type='checkbox' className="form-check-display" id="has-sales-tax-checkbox">
                            <Form.Check.Input
                                name="hasSalesTax"
                                type='checkbox'
                                disabled={isReadOnly}
                                checked={ticket.hasSalesTax}
                                onChange={(e) => { handleChange(e, e.target.checked) }} />
                            <div>
                                <Form.Check.Label id="has-sales-tax-checkbox">Has Sales Tax</Form.Check.Label>
                                <small className='form-check-text'>
                                    Unchecked will remove sales tax from ticket price
                                </small>
                            </div>
                        </Form.Check>
                    </div>
                )}
                <div className="form-group">
                    <Form.Check type='checkbox' className="form-check-display" id="lock-ticket-checkbox">
                        <Form.Check.Input
                            name="lockTicket"
                            type='checkbox'
                            disabled={isReadOnly}
                            checked={ticket.lockTicket}
                            onChange={(e) => { handleChange(e, e.target.checked) }} />
                        <div>
                            <Form.Check.Label id="lock-ticket-checkbox">Lock ticket</Form.Check.Label>
                            <small className='form-check-text'>
                                Once you lock the ticket it will require an access code to purchase
                            </small>
                        </div>
                    </Form.Check>
                    {ticket.lockTicket && (
                        <Form.Group controlId="accessCode" className='form-check-display-form-input'>
                            <div className="form-label--flex">
                                <Form.Label>Access code</Form.Label>
                                <OverlayTrigger
                                    placement="right"
                                    overlay={<Tooltip>Maximum of 12 characters</Tooltip>}>
                                    <Button variant="link">
                                        <InfoIcon />
                                    </Button>
                                </OverlayTrigger>
                            </div>
                            <InputGroup className={`w-50 ${errors?.field === 'accessCode' ? 'input-group-error' : ''}`}>
                                <InputGroup.Text id="accessCode-val"><img src={lock} alt="" /></InputGroup.Text>
                                <Form.Control
                                    name="accessCode"
                                    aria-describedby="accessCode-val"
                                    readOnly={isReadOnly}
                                    value={ticket.accessCode}
                                    placeholder="Access code"
                                    maxLength="12"
                                    onChange={handleChange}
                                    className='text-uppercase'
                                    required
                                />
                            </InputGroup>
                            {errors?.field === 'accessCode' && (
                                <Form.Text className='error'>{errors?.message}</Form.Text>
                            )}
                        </Form.Group>
                    )}
                </div>


                <Form.Group className="form-group" controlId="ticketType">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        type="text"
                        name="name"
                        readOnly={isReadOnly}
                        placeholder="Name of ticket"
                        value={ticket.name}
                        onChange={handleChange}
                        className={`${errors?.field === 'name' ? 'error-border' : ''}`}
                        required
                    />
                </Form.Group>
                {errors?.field === 'name' && (
                    <Form.Text className='error mb-4'>{errors?.message}</Form.Text>
                )}

                <Form.Group className="form-group" controlId="description">
                    <div className="form-label--flex">
                        <Form.Label>Description</Form.Label>
                        <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip>Give attendees some more info about this ticket</Tooltip>}>
                            <Button variant="link">
                                <InfoIcon />
                            </Button>
                        </OverlayTrigger>
                    </div>
                    <Form.Control
                        as="textarea" rows={5}
                        name="description"
                        readOnly={isReadOnly}
                        value={ticket.description} onChange={handleChange}
                    />
                </Form.Group>
                {isPaid && (
                    <>
                        <Row className='form-group'>
                            <Col>
                                <Form.Group>
                                    <Form.Label htmlFor="price">Price per ticket</Form.Label>
                                    <InputGroup className={`${errors?.field === 'price' ? 'input-group-error' : ''}`}>
                                        <InputGroup.Text id="price-val">$</InputGroup.Text>
                                        <Form.Control
                                            placeholder="Price per ticket"
                                            id="price"
                                            name="price"
                                            aria-describedby="price-val"
                                            pattern="^[0-9.]*$"
                                            readOnly={isReadOnly}
                                            value={ticket.price}
                                            onChange={(e) => handleChange(e.target.value === '' || e.target.validity.valid ? e : ticket.price)}
                                            onBlur={handleValid}
                                            className={`${errors?.field === 'price' ? 'error-border' : ''}`}
                                            required
                                        />
                                    </InputGroup>
                                    {errors?.field === 'price' && (
                                        <Form.Text className='error'>{errors?.message}</Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="form-group">
                                    <Form.Label htmlFor="fee">Facility fee</Form.Label>
                                    <InputGroup>
                                        <InputGroup.Text id="fee-val">$</InputGroup.Text>
                                        <Form.Control
                                            placeholder="Facility fee"
                                            id="fee"
                                            name="fee"
                                            aria-describedby="fee-val"
                                            pattern="^[0-9.]*$"
                                            readOnly={isReadOnly}
                                            value={ticket.fee}
                                            onChange={(e) => handleChange(e.target.value === '' || e.target.validity.valid ? e : ticket.fee)}
                                            required
                                        />
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Stack direction="horizontal" className='mt-4'>
                            <Button variant='outline-light' size="sm" className="btn--info ms-auto" onClick={handleShow}>
                                View ticket breakdown
                            </Button>
                        </Stack>
                    </>
                )}

                <Form.Group controlId="quantity" className='form-group'>
                    <Form.Label>Available quantity</Form.Label>
                    <Form.Control
                        type="text"
                        name="quantity"
                        pattern="^[0-9]*$"
                        placeholder="Number of tickets available"
                        readOnly={isReadOnly}
                        value={ticket.quantity}
                        onChange={(e) => handleChange(e.target.value === '' || e.target.validity.valid ? e : ticket.quantity)}
                        onBlur={handleValid}
                        className={errors?.field === 'quantity' ? 'error-border' : ''}
                        required
                    />
                    {errors?.field === 'quantity' && (
                        <Form.Text className='error'>{errors?.message}</Form.Text>
                    )}
                </Form.Group>


                {isPaid && (
                    <>
                        <fieldset className="form-group">
                            <legend>Resale price range</legend>
                            <Row>
                                <Col>
                                    <Form.Label htmlFor="min">Minimum</Form.Label>
                                    <InputGroup className={`${errors?.field === 'minResalePrice' ? 'input-group-error' : ''}`}>
                                        <InputGroup.Text id="min-val">
                                            $
                                        </InputGroup.Text>
                                        <Form.Control placeholder="Minimum Value" id="min" name="minResalePrice"
                                            aria-describedby="min-val"
                                            readOnly={isReadOnly}
                                            value={ticket.minResalePrice || ''}
                                            pattern="^[0-9.]*$"
                                            onChange={(e) => handleChange(e.target.value === '' || e.target.validity.valid ? e : ticket.minResalePrice)}
                                            onBlur={handleValid}
                                            required
                                        />
                                    </InputGroup>
                                    {errors?.field === 'minResalePrice' && (
                                        <Form.Text className='error'>{errors?.message}</Form.Text>
                                    )}
                                </Col>
                                <Col>
                                    <Form.Label htmlFor="max">Maximum</Form.Label>
                                    <InputGroup className={`${errors?.field === 'maxResalePrice' ? 'input-group-error' : ''}`}>
                                        <InputGroup.Text id="max-val">$</InputGroup.Text>
                                        <Form.Control
                                            placeholder="Maximum Value"
                                            id="max"
                                            name="maxResalePrice"
                                            aria-describedby="max-val"
                                            pattern="^[0-9.]*$"
                                            readOnly={isReadOnly}
                                            value={ticket.maxResalePrice || ''}
                                            onChange={(e) => handleChange(e.target.value === '' || e.target.validity.valid ? e : ticket.maxResalePrice)}
                                            onBlur={handleValid}
                                            required
                                        />
                                    </InputGroup>
                                    {errors?.field === 'maxResalePrice' && (
                                        <Form.Text className='error'>{errors?.message}</Form.Text>
                                    )}
                                </Col>
                            </Row>
                        </fieldset>
                    </>
                )}
                <fieldset className="form-group">
                    <legend>When are tickets available</legend>
                    <Row className='pt-1 mb-3'>
                        <Col>
                            <DateInputWrapper
                                label="Sales start"
                                id="sales-start"
                                isReadOnly={isReadOnly}
                                setDate={setSalesStart}
                                selectedDate={salesStart}
                                startDate={salesStart}
                                endDate={salesEnd}
                                maxDate={new Date(eventEnd)}
                                displayEndDate={true}
                                setError={setHasError}
                                error={hasError}
                            />
                        </Col>
                        <Col>
                            <TimeInputWrapper
                                label="Start time"
                                id="sales-start-time"
                                isReadOnly={isReadOnly}
                                setDate={setSalesStart}
                                selectedDate={salesStart}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <DateInputWrapper
                                label='Sales end'
                                id="sales-end"
                                isReadOnly={isReadOnly}
                                setDate={setSalesEnd}
                                selectedDate={salesEnd}
                                startDate={salesStart}
                                endDate={salesEnd}
                                maxDate={new Date(eventEnd)}
                                displayEndDate={true}
                                setError={setHasError}
                                error={hasError}
                            />

                        </Col>
                        <Col>
                            <TimeInputWrapper
                                label="End time"
                                id="sales-end-time"
                                isReadOnly={isReadOnly}
                                setDate={setSalesEnd}
                                selectedDate={salesEnd}
                            />
                        </Col>
                    </Row>
                </fieldset>
                <fieldset className="form-group">
                    <legend>Tickets per order</legend>
                    <Row>
                        <Col>
                            <Form.Group controlId="minQuantity">
                                <Form.Label>Minimum quantity</Form.Label>
                                <Form.Control placeholder="Minimum quantity"
                                    pattern="^[0-9]*$"
                                    name="minQuantity"
                                    readOnly={isReadOnly}
                                    value={ticket.minQuantity}
                                    onChange={(e) => handleChange(e.target.value === '' || e.target.validity.valid ? e : ticket.minQuantity)}
                                    onBlur={handleValid}
                                    className={`${errors?.field === 'minQuantity' ? 'error-border' : ''}`}
                                    required
                                />
                            </Form.Group>
                            {errors?.field === 'minQuantity' && (
                                <Form.Text className='error'>{errors?.message}</Form.Text>
                            )}
                        </Col>
                        <Col>
                            <Form.Group controlId="maxQuantity">
                                <Form.Label>Maximum quantity</Form.Label>
                                <Form.Control placeholder="Maximum quantity"
                                    pattern="^[0-9]*$"
                                    name="maxQuantity"
                                    readOnly={isReadOnly}
                                    value={ticket.maxQuantity}
                                    onChange={(e) => handleChange(e.target.value === '' || e.target.validity.valid ? e : ticket.maxQuantity)}
                                    onBlur={handleValid}
                                    className={`${errors?.field === 'maxQuantity' ? 'error-border' : ''}`}
                                    required
                                />
                            </Form.Group>
                            {errors?.field === 'maxQuantity' && (
                                <Form.Text className='error'>{errors?.message}</Form.Text>
                            )}
                        </Col>
                    </Row>
                </fieldset>
            </Form>

            <TicketBreakdownModal show={show} handleClose={handleClose} ticket={ticket} fees={fees} taxRates={taxRates} />
        </>
    );
}
