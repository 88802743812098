import { useParams } from 'react-router-dom';

import { PrimaryTicketsWrapper } from "../../components";

export default function PrimaryTicketsPage() {

    const { uuid } = useParams()

    return (
        <PrimaryTicketsWrapper eventId={uuid} />
    )
}