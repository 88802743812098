import React, { useEffect } from 'react';

import Stack from 'react-bootstrap/Stack';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import './bankCard.scss';

export default function BankCard({ handleShow, bankAccount, handleShowDelete }) {

    const lastN = (str, int) => {
        return str?.slice(`-${int}`);
    }

    useEffect(() => {

    }, [bankAccount])

    return (
        <>
            <Row id="bank-card">
                <Col md={3}>
                    <div className='block bg-info'></div>
                </Col>
                <Col>
                    <Stack className="info">
                        <h1>Bank information</h1>
                        <ul className='text-muted small'>
                            <li>{bankAccount?.external_accounts?.data[0]?.bank_name}</li>
                            <li>XXXXXX{bankAccount?.external_accounts?.data[0]?.last4}</li>
                            <li>{bankAccount?.external_accounts?.data[0]?.country}. Dollars, United States</li>
                        </ul>
                        <Stack direction='horizontal' gap={3} className="mt-3">
                            <Button variant="link" className='text-danger' onClick={() => handleShowDelete(bankAccount)}>Delete</Button>
                        </Stack>
                    </Stack>
                </Col>
            </Row>
        </>
    );
}
