import { useState, useLayoutEffect, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom'

import authService from './utilities/services/auth.service';
import UserContext from './context/User/User';
import LoadingContext from './context/Loading/Loading'

import { getOrganizationPermissions } from './utilities/api';
import { toggleContainer, toggleSpacing, changeBackground } from './utilities/helpers';

import { Navigation } from './components';

import Router from './Router';

function App() {

    let location = useLocation();

    const user = authService.getUser()
    const me = useContext(UserContext)

    const [
        authenticated,
        setAuthenticated
    ] = useState(false);

    const [isLoading, setIsLoading] = useState(false)

    const [orgPermissions, setOrgPermissions] = useState([])

    const [
        org,
        setOrganization
    ] = useState('');

    useLayoutEffect(() => {
        changeBackground(location.pathname)
        toggleContainer(location.pathname);
        toggleSpacing(location.pathname);
    }, [location.pathname])

    useEffect(() => {
        let permissions = [];
        // Need to refactor to not hit db on every route change
        getOrganizationPermissions().then((res) => {
            Object.values(res.data.data).map(obj => permissions.push({ id: obj?.id, name: obj?.attributes?.name }))
            setOrgPermissions(permissions);
        })
    }, [location])

    const showLoading = () => {
        setIsLoading(true)
    }

    const hideLoading = () => {
        setIsLoading(false)
    }

    return (
        <div className="App">
            <LoadingContext.Provider value={{ isLoading, showLoading, hideLoading }}>
                <UserContext.Provider value={{ authenticated, setAuthenticated, user, setOrganization, orgPermissions }}>
                    <Navigation user={user} me={me} />
                    <div className="container" id="main-container">
                        <Router />
                    </div>
                </UserContext.Provider>
            </LoadingContext.Provider>
        </div>
    );
}

export default App;
