import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEqual } from 'lodash'

import { createEventReport } from '../../../utilities/api'
import { emailPatternMatch } from "./../../../utilities/helpers";

import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import { UserInfo } from './UserInfo';
import { Spinner } from '../../LoadingContainer/Spinner';

import './userInformationWrapper.scss'

export default function UserInformationWrapper({ eventId, id, report }) {

    const tagInput = useRef();

    const navigate = useNavigate();

    const [initialEditState, setInitialEditState] = useState()

    const [tags, setTags] = useState([]);

    const [isSaving, setIsSaving] = useState(false)

    const [isDisabled, setIsDisabled] = useState(false)

    // set state on edit 
    useEffect(() => {
        if (id && report) {
            setTags(report.contacts.map(contact => contact.emailAddress))
        }
    }, [report])

    useEffect(() => {
        if (id) setInitialEditState({ tags: report?.contacts.map(contact => contact.emailAddress) })
    }, [report])

    // save initial state to check if button should be disabled 
    useEffect(() => {
        setIsDisabled(isEqual(initialEditState?.tags, tags) || tags.length === 0 || isSaving)
    }, [initialEditState, tags, isSaving])

    const handleChange = (e) => {
        const val = e.target.value;
        const currVal = val.trim().substring(val.lastIndexOf(' '))

        if (e.key === 'Enter') e.preventDefault();

        if (e.key === ' ' && val) {
            if (tags.find(tag => tag.toLowerCase() === currVal.toLowerCase()))
                return;
            if (emailPatternMatch(currVal.trim())) {
                setTags([...tags, currVal])
                tagInput.current.value = null;
            }

        } else if (e.key === 'Backspace' && (val === ' ' || !val)) {
            removeTag(tags.length - 1);
        }
    }

    const removeTag = id => {
        const newTags = [...tags]
        newTags.splice(id, 1);
        setTags(newTags)
    }

    const handleSave = () => {
        setIsSaving(true)
        let data = {
            sendTo: tags,
            eventId,
            id
        }

        createEventReport(data)
            .then((res) => {
                setIsSaving(false)
                navigate(-1)
            })
            .catch((err) => {
                console.error(err)
                setIsSaving(false)
            })
    }

    return (
        <section className='wrapper'>
            <section>
                <header className="section-header section-heading">
                    <h1>{id ? 'Edit recipient' : 'Recipient'} information</h1>
                </header>
                <div className='mb-4'>
                    <Alert bg="default" className='alert-info alert-info--light'>
                        <p>This report will be set out daily at 8am EST.</p>
                    </Alert>
                </div>
                <Card body className='card--sm'>
                    <UserInfo id={id} report={report} reference={tagInput} tags={tags} removeTag={removeTag} handleChange={handleChange} />
                </Card>
            </section>
            <Stack direction="horizontal" className="btn-group-flex">
                <Button variant="outline-light" size="lg" onClick={() => navigate(-1)}>Cancel</Button>
                <Button
                    size="lg"
                    disabled={isDisabled}
                    onClick={handleSave}
                    className="btn-width-sm"

                >
                    {isSaving ? (
                        <Spinner />
                    ) : (
                        <>
                            {id ? 'Update report' : 'Add recipient'}
                        </>
                    )}
                </Button>
            </Stack>
        </section>
    );
}
