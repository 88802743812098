import React, { useRef } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment'

export default function TimeInput({ id, selectedDate, setChosenDate, filterPassedTime, reference, isReadOnly }) {

    const timeRef = useRef();

    const handleChange = date => {
        if (date) {
            const formattedDate = moment(date, 'hh:mm A')
            if (formattedDate.isValid()) {
                // replace just the time part of the date so date doesn't change to today's date when manually entering time 
                const selectedDateFormat = moment(selectedDate).set({ 'hour': moment(formattedDate).hours(), 'minute': moment(formattedDate).minute() })
                setChosenDate(new Date(selectedDateFormat))
            }
        }
    }

    return (
        <DatePicker
            ref={timeRef}
            showPopperArrow={false}
            id={id}
            readOnly={isReadOnly}
            selected={selectedDate}
            onChange={(date) => handleChange(date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
            filterTime={filterPassedTime}
            wrapperClassName="date-picker"
            calendarClassName='calendar-container'
            popperClassName='popper-container'
            popperModifiers={[
                {
                    name: "offset",
                    options: {
                        offset: ({ placement }) => {
                            if (placement === 'top-start') {
                                return [10, 25]
                            } else {
                                return [];
                            }
                        }
                    },
                },
                {
                    name: 'preventOverflow',
                    options: {
                        boundary: reference.current, // align calendar to edge of date card 
                        altBoundary: true,
                        tether: false,
                        tetherOffset: 5
                    },
                },
            ]}
        />
    );
}
