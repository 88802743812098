import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { isEqual } from 'lodash'

import AuthService from '../../../utilities/services/auth.service';

import { getEvent, getTicketDetails, getAttendeesList, messageAttendees } from '../../../utilities/api';

import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import { LoadingContainer } from '../../LoadingContainer';
import { Spinner } from '../../LoadingContainer/Spinner';
import { ContactForm } from './ContactForm';
import { ConfirmationModal } from './ConfirmationModal';

export default function ContactAttendeeWrapper({ eventId, id, campaign }) {

    const user = AuthService.getUser()

    const navigate = useNavigate();

    const toOpt = [
        {
            label: 'All attendees',
            value: "all"
        },
        {
            label: 'Attendees by ticket type',
            value: "by_ticket_type"
        },
        {
            label: 'Specific Attendees',
            value: "by_specific"
        }
    ]

    const [initialEditState, setInitialEditState] = useState()

    const [event, setEvent] = useState()

    const [tickets, setTickets] = useState()

    const [attendees, setAttendees] = useState()

    const [toOption, setToOption] = useState(toOpt[0].value)

    const [replyTo, setReplyTo] = useState("")

    const [testEmail, setTestEmail] = useState("");

    const [subject, setSubject] = useState("")

    const [sendTo, setSendTo] = useState([])

    const [title, setTitle] = useState('')

    const [message, setMessage] = useState('')

    const [sendDate, setSendDate] = useState(new Date());

    const [choice, setChoice] = useState(1)

    const [show, setShow] = useState(false);

    const [isSending, setIsSending] = useState(false)

    const [disableTest, setDisableTest] = useState(false)

    const [isDisabled, setIsDisabled] = useState(false)

    // to show edit form after API calls are made and initial state is set 
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        let cancel = false;

        setIsLoading(true)
        getEvent(eventId)
            .then((res) => {
                if (cancel) return
                setEvent(res.data)

                getTicketDetails(eventId)
                    .then((res) => {
                        setTickets(res.data)
                    })
                    .catch((err) => {
                        console.error(err)
                        setIsLoading(false)
                    })

                getAttendeesList(eventId)
                    .then((res) => {
                        // remove duplicate attendees
                        const noDuplicateAttendees = res?.data?.attendees.reduce((acc, current) => {
                            const duplicate = acc.find(attendee => attendee.users_permissions_user?.uuid === current.users_permissions_user?.uuid)

                            // push returns the length of updated array while concat returns a new array 
                            if (!duplicate) {
                                return acc.concat([current]);
                            }
                            else {
                                return acc;
                            }
                        }, []);

                        // remove guests
                        setAttendees(noDuplicateAttendees.filter(attendee => attendee.ticket))

                        setIsLoading(false)
                    })
                    .catch((err) => {
                        console.error(err)
                        setIsLoading(false)
                    })

            })
            .catch((err) => {
                console.error(err)
                setIsLoading(false)
            })

        return () => {
            // when component unmounts because state updates campaign
            cancel = true
        }

    }, [eventId])

    // get campaign if editing 
    useEffect(() => {
        if (id && campaign) {
            setReplyTo(campaign?.replyTo)
            setSubject(campaign?.subject)
            setMessage(campaign?.message)
            setTitle(campaign?.title)
            setToOption(campaign?.selectedBy)
            setChoice(campaign?.scheduled ? 2 : 1)
            setSendDate(new Date(campaign?.scheduledTime))
            // set the sendTo values
            if (campaign?.selectedBy === 'by_specific') {
                setSendTo(campaign?.contacts.map(contact => contact.uuid))
            }
            else if (campaign?.selectedBy === 'by_ticket_type') {
                setSendTo(campaign?.selectedType.split(","))
            }
        }
    }, [campaign])

    // if email is set for later date and is not editing, set send email date 3 hours ahead of today's date 
    useEffect(() => {
        if (!id) {
            if (choice === 2) {
                const sendDate = moment().add(3, 'h')
                setSendDate(new Date(sendDate))
            }
        }
    }, [id, choice])


    useEffect(() => {
        if (id) setInitialEditState({ replyTo, toOption, subject, sendTo, title, message, choice, sendDate })
    }, [id, isLoading])

    // save initial state to check if button should be disabled 
    useEffect(() => {
        setIsDisabled((initialEditState?.replyTo === replyTo && initialEditState?.toOption === toOption && isEqual(initialEditState?.sendTo, sendTo) && initialEditState?.subject === subject && initialEditState?.title === title && initialEditState?.message === message && initialEditState?.choice === choice && isEqual(initialEditState?.sendDate, sendDate)) || !replyTo || !subject || !title || !message || checkToValue() || isSending)
    }, [initialEditState, replyTo, toOption, subject, title, message, choice, sendDate, sendTo, isSending])

    useEffect(() => {
        if (user) {
            if (!id) {
                setReplyTo(user.user.email);
            }
            setTestEmail(user.user.email);
        }
    }, [])

    // disable button depending on To field value 
    const checkToValue = () => {
        if (toOption !== 'all') {
            return sendTo?.length === 0
        }
        else return false
    }

    const handleChoice = (e) => {
        setChoice(Number(e.target.id))
    }

    const handleShow = () => setShow(true)

    const handleClose = () => {
        setShow(false)
        let type = choice === 1 ? 'sent' : 'scheduled'
        navigate(`/myevent/${eventId}/contact-attendees?msg=${type}`)
    }

    const handleClick = () => {
        submit()
    }

    const messageDetails = async (sendTest = false) => {
        let data = {
            replyTo,
            title,
            subject,
            message,
            testEmail,
            toOption,
            sendTo,
            sendTest,
            eventUUID: eventId,
            choice,
            sendDate,
            isEditing: id ? true : false,
            campaignId: id ? id : ''
        }

        if (sendTest) setDisableTest(true)

        messageAttendees(data)
            .then(() => {
                setIsSending(false)
                if (!sendTest) handleShow()
            })
            .catch((err) => {
                setIsSending(false)
                console.error(err)
            })
    }

    const sendTest = () => {
        messageDetails(true)
    }

    const submit = () => {
        setIsSending(true)
        messageDetails(false)
    }

    return (
        <>
            {isLoading ? (
                <LoadingContainer />
            ) : (
                <>
                    <section className='wrapper'>
                        <section>
                            <header className="section-header section-heading section-heading--secondary">
                                <h1>{id ? 'Edit contact attendee' : 'Contact attendee'}</h1>
                            </header>
                            <Card body className='card--sm'>
                                <ContactForm id={id} event={event} tickets={tickets} attendees={attendees} replyTo={replyTo} setReplyTo={setReplyTo} testEmail={testEmail} setTestEmail={setTestEmail} subject={subject} setSubject={setSubject} sendTo={sendTo} setSendTo={setSendTo}
                                    toOpt={toOpt} setToOption={setToOption} toOption={toOption} sendTest={sendTest} disableTest={disableTest} setDisableTest={setDisableTest} checkToValue={checkToValue}
                                    setTitle={setTitle} title={title} setMessage={setMessage} message={message} handleChoice={handleChoice} choice={choice} setDate={setSendDate} date={sendDate} />
                            </Card>
                        </section>
                        <Stack direction="horizontal" className="btn-group-flex">
                            <Button variant="outline-light" size="lg" onClick={() => navigate(-1)}>Cancel</Button>
                            <Button
                                size="lg"
                                className={`btn-width-md ${!isSending ? `btn-${choice == 1 ? 'send' : 'schedule'}` : ''} `}
                                disabled={isDisabled}
                                onClick={handleClick}>
                                {isSending ? (
                                    <Spinner />
                                ) : (
                                    <>
                                        {choice === 1 ? 'Send' : 'Schedule'}
                                    </>
                                )}
                            </Button>
                        </Stack>
                    </section>

                    <ConfirmationModal choice={choice} show={show} handleClose={handleClose} />
                </>
            )}
        </>
    );
}
