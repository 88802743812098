import React, { useState } from 'react';

import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';

import { Report } from './Report'
import { EmptyContainer } from '../../EmptyContainer';
import { DeleteModal } from '../../DeleteModal';

export default function Reports({ reports, handleClick, isRemoving, removeReport }) {

    const [show, setShow] = useState(false)

    // id to remove 
    const [id, setId] = useState()

    const handleShow = (id) => {
        setShow(true);
        setId(id)
    }

    const handleClose = () => {
        setShow(false);
        setId()
    }

    const handleDelete = () => {
        removeReport(id).then(() => {
            handleClose();
        })
    }

    return (
        <>
            <Stack direction='horizontal' className='mb-5'>
                <Button size="lg" variant="outline-light" className="btn-plus btn-plus--dark ms-auto" onClick={handleClick}>Add recipient</Button>
            </Stack>
            <div className='mb-4'>
                <Alert bg="default" className='alert-info alert-info--light'>
                    <p>Reports will be sent out to selected recipients daily at 8am EST.</p>
                </Alert>
            </div>
            <Card body>
                {reports && reports.length > 0 ? (
                    <div className="list-table">
                        <div className="flex-row list-table-header" role="rowgroup">
                            <div className="list-table-col list-table-col-header lg" role="columnheader">
                                <span>Emails</span>
                            </div>
                        </div>
                        {reports.map((report, index) => (
                            <Report key={index} report={report} handleShow={handleShow} handleClick={handleClick} />
                        ))}
                    </div>

                ) : (
                    <EmptyContainer>
                        <p>Nothing is scheduled, click Add recipient to schedule a daily ticket count to be sent.</p>
                    </EmptyContainer>
                )}
            </Card>
            <DeleteModal entity="recipient" show={show} handleClose={handleClose} isRemoving={isRemoving} handleDelete={handleDelete} />
        </>

    );
}
