import React, { useEffect, useState, useContext } from 'react';

import LoadingContext from '../../context/Loading/Loading';
import AuthService from '../../utilities/services/auth.service';
import UserContext from '../../context/User/User';

import { getPaymentInfo, removeBankAccount, createPaymentInfo } from '../../utilities/api';
import { checkPermission } from '../../utilities/helpers';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import { BankCard } from "./BankCard";
import { BankAccountDetailsModal } from "./BankAccountDetailsModal";
import { NoPermissionsContainer } from '../NoPermissionsContainer';
import { PageLoadingContainer } from '../PageLoadingContainer';

export default function PaymentInformationWrapper() {

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const { orgPermissions } = useContext(UserContext)

    const { getPermissions } = AuthService;

    const [hasPermission, setHasPermission] = useState(true);

    const [bankAccount, setBankAccount] = useState()

    const [show, setShow] = useState(false)

    const [isSaving, setIsSaving] = useState(false);

    const [disabledBtn, setDisableBtn] = useState(false)

    useEffect(() => {
        if (orgPermissions?.length > 0) setHasPermission(checkPermission(orgPermissions, getPermissions(), 8));

    }, [orgPermissions])

    useEffect(() => {
        showLoading();
        getPaymentInfo()
            .then((res) => {
                console.log(res.data)
                setBankAccount(res.data)
                getAccountInfo()
            })
            .catch((err) => {
                console.error(err)
                hideLoading()
            })
    }, [])

    const getAccountInfo = () => {
        getPaymentInfo()
            .then((res) => {
                setBankAccount(res.data)
                hideLoading()
            })
            .catch((err) => {
                console.error(err)
                hideLoading()
            })
    }

    const handleShow = () => {
        setDisableBtn(true)
        createPaymentInfo({ data: bankAccount })
            .then((res) => {
                window.location.href = res?.data
            })
            .catch((err) => {
                console.error(err)
            })
    }

    const handleClose = () => setShow(false)

    const handleShowDelete = (account) => {
        removeBankAccount({})
            .then(res => {
                getAccountInfo()
            })
            .catch((err) => console.error(err))
    }

    const submit = () => {
        setIsSaving(true)
        createPaymentInfo({ data: bankAccount })
            .then((res) => {
                window.location.href = res?.data
                setShow(false)
                setIsSaving(false)
            })
            .catch((err) => {
                setIsSaving(false)
                console.error(err)
            })
    }

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <div className='position-relative'>
                    <section className={`wrapper ${!hasPermission ? 'overlay' : ''}`}>
                        <header className="section-header">
                            <div className="section-heading section-heading--secondary">
                                <h1>Payment information</h1>
                            </div>
                            <p className='section-header-desc'>The money you make from your events will be issued to the bank account associated with this account</p>
                        </header>
                        <Card body className='card--sm'>
                            {bankAccount ? (
                                <BankCard handleShow={handleShow} bankAccount={bankAccount} handleShowDelete={handleShowDelete} />
                            ) : (
                                <Button size="lg" className="btn-plus w-100" onClick={handleShow} disabled={disabledBtn}>Link bank account</Button>
                            )}
                        </Card>
                    </section>

                    <BankAccountDetailsModal show={show} handleClose={handleClose} getBankAccount={setBankAccount} account={bankAccount} submit={submit} isSaving={isSaving} />

                    {!hasPermission && (
                        <NoPermissionsContainer />
                    )}
                </div>
            )}
        </>
    );
}
