import React from 'react';

import { formatCurrency, formatNumber, getRoyalties } from '../../../utilities/helpers';

import Table from 'react-bootstrap/Table';

import { ExportBtn } from '../../ExportBtn';

export default function ResaleTickets({ orders, feeStructure }) {

    const getSum = (arr) => arr.reduce((pv, cv) => pv + cv, 0)

    const totalQuantity = () => {
        let quantity = orders?.map(order => order?.details?.ticketCount)
        return getSum(quantity)
    }

    const totalGross = () => {
        let gross = orders?.map(order => Number(order?.details?.listing?.pricing?.ticketCostWithFees))
        return getSum(gross)
    }

    const totalRoyalties = () => {
        let buyerFee = orders?.map(order => parseFloat(order?.total) * parseFloat(feeStructure.secondaryServiceFeeBuyer) / 100)
        let sellerFee = orders?.map(order => parseFloat(order?.total) * parseFloat(feeStructure.secondaryServiceFeeSeller) / 100)
        let blockticketsRoyalty = parseFloat((getSum(buyerFee) + getSum(sellerFee)) / 2)
        return blockticketsRoyalty
    }

    // add feeStructure to each order for excel export 
    const data = orders?.map(order => ({ ...order, feeStructure }))

    return (
        <section>
            <header className='section-header'>
                <div className="actions-group-flex">
                    <ExportBtn data={data} exportedData="sales" />
                </div>
            </header>
            <div className="table-container">
                <Table>
                    <thead>
                        <tr>
                            <th>Ticket type</th>
                            <th>Price</th>
                            <th>Quantity</th>
                            <th>Gross</th>
                            <th>Royalties</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders && orders?.map((order, index) => (
                            <tr key={index}>
                                <td>{order?.details?.listing?.tickets[0]?.name}</td>
                                <td>{formatCurrency(order?.details?.listing?.askingPrice)}</td>
                                <td>{formatNumber(order?.details?.listing?.quantity)}</td>
                                <td>{formatCurrency(order?.details?.listing?.pricing?.ticketCostWithFees)}</td>
                                <td>{formatCurrency(getRoyalties(order, feeStructure))}</td>
                            </tr>
                        ))
                        }
                        <tr className='total-row'>
                            <td colSpan={2}>Total</td>
                            <td>{formatNumber(totalQuantity())}</td>
                            <td>{formatCurrency(totalGross())}</td>
                            <td>{formatCurrency(totalRoyalties())}</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </section>
    );
}
