import React from 'react';

import { formatCurrency, formatNumber } from '../../../utilities/helpers';

import Table from 'react-bootstrap/Table';

import { ExportBtn } from '../../ExportBtn';

export default function PrimaryTickets({ tickets, capacity, ticketsRemaining }) {

    const getSum = (arr) => arr.reduce((pv, cv) => pv + cv, 0)

    const totalQuantity = () => {
        let quantity = tickets?.map(ticket => ticket.quantity)
        return getSum(quantity)
    }

    const totalGross = () => {
        let gross = tickets?.map(ticket => Number(ticket.gross))
        return getSum(gross)
    }

    const totalNet = () => {
        let net = tickets?.map(ticket => Number(ticket.net))
        return getSum(net)
    }

    return (
        <section>
            <header className='section-header'>
                <div className="actions-group-flex">
                    <ExportBtn data={tickets} exportedData="sales" capacity={capacity} />
                </div>
            </header>
            <div className="table-container">
                <Table>
                    <thead>
                        <tr>
                            <th>Ticket type</th>
                            <th>Price</th>
                            <th>Quantity</th>
                            <th>Gross</th>
                            <th>Net sales</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tickets && tickets?.map((ticket, index) => (
                            <tr key={index}>
                                <td>{ticket?.ticketType}</td>
                                <td>{formatCurrency(ticket?.price)}</td>
                                <td>{formatNumber(ticket?.quantity)}</td>
                                <td>{formatCurrency(ticket?.gross)}</td>
                                <td>{formatCurrency(ticket?.net)}</td>
                            </tr>
                        ))
                        }
                        <tr className='total-row'>
                            <td colSpan={2}>Total</td>
                            <td>{formatNumber(totalQuantity())}</td>
                            <td>{formatCurrency(totalGross())}</td>
                            <td>{formatCurrency(totalNet())}</td>
                        </tr>

                        <tr className='total-row'>
                            <td colSpan={2}>Tickets remaining</td>
                            <td>{formatNumber(ticketsRemaining)}</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr className='total-row'>
                            <td colSpan={2}>Capacity</td>
                            <td>{formatNumber(capacity)}</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </section>
    );
}
