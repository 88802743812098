import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { isEqual } from 'lodash'

import LoadingContext from '../../context/Loading/Loading';
import AuthService from '../../utilities/services/auth.service';
import UserContext from '../../context/User/User';
import { noTimeZoneDate, noTZDate } from '../../utilities/helpers';

import { createEvent, getEvent, editEvent, getOrgVenues } from '../../utilities/api';
import { checkPermission, isEventPast } from '../../utilities/helpers';

import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';

import { BasicInfo } from './BasicInfo';
import { DateTime } from './DateTime';
import { Location } from './Location';
import { CreateEventButtons } from '../CreateEventButtons';
import { NoPermissionsContainer } from '../NoPermissionsContainer';
import { PageLoadingContainer } from '../PageLoadingContainer';

export default function BasicInfoWrapper({ eventId }) {

    const navigate = useNavigate();

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const { orgPermissions } = useContext(UserContext)

    const organization = AuthService.getOrg()[0];

    const { getPermissions } = AuthService;

    const [hasPermission, setHasPermission] = useState(true);

    const [initialState, setInitialState] = useState();

    const [showFooter, setShowFooter] = useState(false)

    const [eventStart, setEventStart] = useState(new Date(moment('7:00 pm', 'h:mm a').format()));

    const [eventEnd, setEventEnd] = useState(new Date(moment('10:00 pm', 'h:mm a').format()));

    const [doorsOpen, setDoorsOpen] = useState(new Date(moment('6:00 pm', 'h:mm a').format()));

    const [hasError, setHasError] = useState(false)

    const [timeError, setTimeError] = useState(false)

    const [venues, setVenues] = useState()

    const [event, setEvent] = useState({
        presentedBy: '',
        name: '',
        venue: '',
        display_start_time: true,
        hide_end_date: true
    })

    const [alert, setAlert] = useState({
        show: false,
        variant: '',
        message: ''
    })

    const [isSaving, setIsSaving] = useState(false)

    useEffect(() => {
        if (orgPermissions?.length > 0) setHasPermission(checkPermission(orgPermissions, getPermissions(), 3));
    }, [orgPermissions])

    useEffect(() => {
        showLoading()
        getOrgVenues()
            .then((res) => setVenues(res.data))
            .catch((err) => {
                console.error(err)
                hideLoading()
            })

        if (!eventId) {
            // save initial state to check whether to show save buttons
            setInitialState({
                event,
                eventStart,
                eventEnd,
                doorsOpen
            })
            hideLoading()

            return
        };

        getEvent(eventId)
            .then((res) => {
                setEvent(res?.data)
                setEventStart(new Date(noTZDate(res?.data?.start, res?.data?.timezone?.offset)))
                setEventEnd(new Date(noTZDate(res?.data?.end, res?.data?.timezone?.offset)))
                // already saved with the timezone - based off of event start
                setDoorsOpen(new Date(noTZDate(res?.data?.doorsOpen, res?.data?.timezone?.offset)))
                // save initial state again if editing to check whether to show save buttons
                setInitialState({
                    event: res?.data,
                    eventStart: new Date(noTZDate(res?.data?.start, res?.data?.timezone?.offset)),
                    eventEnd: new Date(noTZDate(res?.data?.end, res?.data?.timezone?.offset)),
                    doorsOpen: new Date(noTZDate(res?.data?.doorsOpen, res?.data?.timezone?.offset))
                })
                hideLoading()
            })
            .catch((err) => {
                console.error(err)
                hideLoading()
            })

    }, [])

    useEffect(() => {
        // make doors open the same as event start when event start changes 
        // create a moment object
        // const doorsOpenDateObj = moment(doorsOpen)

        // do changes on that object
        // doorsOpenDateObj.set('year', moment(eventStart).year());
        // doorsOpenDateObj.set('month', moment(eventStart).month());
        // doorsOpenDateObj.set('date', moment(eventStart).date());
        // setDoorsOpen(new Date(doorsOpenDateObj))

    }, [eventStart])

    // validation for doors open when doors open changes
    useEffect(() => {
        setTimeError(doorsOpen.getTime() >= eventStart.getTime())
    }, [doorsOpen])

    // always set doors open an hour behind event start 
    useEffect(() => {
        // const doorsOpenTime = moment(eventStart).subtract(1, 'h')
        // setDoorsOpen(new Date(doorsOpenTime))

    }, [eventStart])

    useEffect(() => {
        // Listens for changes on event
    }, [event])

    // show/hide footer using lodash
    useEffect(() => {
        if ((!isEqual(initialState?.event, event)) || !isEqual(new Date(initialState?.eventStart), new Date(eventStart)) || !isEqual(new Date(initialState?.eventEnd), new Date(eventEnd)) || !isEqual(new Date(initialState?.doorsOpen), new Date(doorsOpen))) setShowFooter(true)

        else setShowFooter(false)

    }, [event, initialState, event.presentedBy, event.name, event.venue, event.display_start_time, event.hide_end_date, eventStart, eventEnd, doorsOpen])

    const handleChange = (e, val = e.target.value) => {
        setEvent({ ...event, [e.target.name]: val })
    }

    const handleSave = () => {
        setIsSaving(true)
        // create event 
        const data = {};
        data['name'] = event.name;
        // data['summary'] = description;
        data['presentedBy'] = event.presentedBy;
        data['start'] = moment(eventStart).format();
        data['end'] = moment(eventEnd).format();
        data['venue'] = (Number(event.venue));
        data['status'] = 'unpublished';
        data['currency'] = 'usd';
        data['online_event'] = false;
        data['organizationId'] = organization?.id;
        data['hide_end_date'] = event.hide_end_date;
        data['doorsOpen'] = moment(doorsOpen).format();
        data['display_start_time'] = event.display_start_time;
        if (eventId) {
            data['uuid'] = eventId;
            if (isNaN(data.venue)) data['venue'] = event.venue.id
            editEvent({ data })
                .then((res) => {
                    setIsSaving(false)
                    window.scrollTo(0, 0)
                    // reload sidenav if start date changes
                    if (!isEqual(new Date(initialState?.eventStart), new Date(eventStart))) {
                        window.location.reload()
                    }
                    // save initial state again if editing to check whether to show save buttons
                    setInitialState({
                        event,
                        eventStart,
                        eventEnd,
                        doorsOpen
                    })
                    setAlert({
                        show: true,
                        variant: 'success',
                        message: 'Your info has been updated.'
                    })
                })
                .catch((err) => {
                    console.error(err)
                    window.scrollTo(0, 0)
                    setIsSaving(false)
                    setAlert({
                        show: true,
                        variant: 'danger',
                        message: 'Unable to save info please try again.'
                    })
                })

        } else {
            createEvent({ data })
                .then((res) => {
                    setIsSaving(false)
                    navigate(`/myevent/${res.data?.data?.attributes?.uuid}/details`)
                })
                .catch((err) => {
                    window.scrollTo(0, 0)
                    console.error(err)
                    setIsSaving(false)
                    setAlert({
                        show: true,
                        variant: 'danger',
                        message: 'Unable to save info please try again.'
                    })
                })
        }
    }

    const isReadOnly = () => {
        if (!eventId) return false

        else return isEventPast(event)
    }

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <div className='position-relative'>
                    <section className={`wrapper event-form ${!hasPermission ? 'overlay' : ''}`}>
                        {alert.show &&
                            <>
                                <Alert variant={alert.variant} className="mb-5" onClose={() => setAlert({ show: false, variant: '', message: '' })} dismissible>
                                    <p>{alert.message}</p>
                                </Alert>
                            </>
                        }
                        <section>
                            <header className="section-header-sm section-heading section-heading--secondary">
                                <h1>Basic info</h1>
                            </header>
                            <Card body className='card--sm'>
                                <BasicInfo isReadOnly={isReadOnly()} handleChange={handleChange} event={event} />
                            </Card>
                        </section>
                        <section>
                            <header className="section-header-sm section-heading section-heading--secondary">
                                <h1>Date & Time</h1>
                            </header>
                            <Card body className='card--sm'>
                                <DateTime event={event} isReadOnly={isReadOnly()} handleChange={handleChange} setEventStart={setEventStart} eventStart={eventStart} setEventEnd={setEventEnd} eventEnd={eventEnd} setDoorsOpen={setDoorsOpen} doorsOpen={doorsOpen} setError={setHasError} error={hasError} timeError={timeError} />
                            </Card>
                        </section>
                        <section>
                            <header className="section-header-sm section-heading section-heading--secondary">
                                <h1>Location</h1>
                            </header>
                            <Card body className='card--sm'>
                                <Location event={event} isReadOnly={isReadOnly()} handleChange={handleChange} venues={venues} />
                            </Card>
                        </section>
                    </section>


                    {showFooter && (
                        <CreateEventButtons isEditing={eventId ? true : false} isDisabled={hasError || timeError || !event.name || !event.venue || isSaving} isSaving={isSaving} handleSave={handleSave} styles={`${!eventId ? 'without-sidebar' : ' '} ${!hasPermission ? 'overlay' : ''} `} />
                    )}


                    {!hasPermission && (
                        <NoPermissionsContainer />
                    )}
                </div>
            )}
        </>
    );
}
