import React, { useState, useEffect, useContext } from 'react';

import LoadingContext from '../../context/Loading/Loading';
import AuthService from '../../utilities/services/auth.service';
import UserContext from '../../context/User/User';

import { getMyOrganizations, updateOrgDetails } from '../../utilities/api';
import { checkPermission } from '../../utilities/helpers';

import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import { OrganizationInformationWrapper } from "../OrganizationInformationWrapper";
import { NoPermissionsContainer } from '../NoPermissionsContainer';
import { Spinner } from '../LoadingContainer/Spinner'
import { PageLoadingContainer } from '../PageLoadingContainer';

// Settings Organization Information page 
export default function OrganizationInfoWrapper() {

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const { orgPermissions } = useContext(UserContext)

    const { getPermissions } = AuthService;

    const [hasPermission, setHasPermission] = useState(true);

    const [orgInfo, setOrgInfo] = useState()

    const [initialState, setInitialState] = useState()

    const [alert, setAlert] = useState({
        show: false,
        variant: '',
        message: ''
    })

    const [isDisabled, setIsDisabled] = useState(true)

    const [isSaving, setIsSaving] = useState(false)

    useEffect(() => {
        if (orgPermissions?.length > 0) setHasPermission(checkPermission(orgPermissions, getPermissions(), 2));

    }, [orgPermissions])

    useEffect(() => {
        showLoading()
        getMyOrganizations()
            .then((res) => {
                setOrgInfo(res.data[0])
                // save initial state to check whether to disable button
                setInitialState(res.data[0])
                hideLoading()
            }).catch((err) => {
                console.log(err)
                hideLoading()
            })
    }, [])

    // enable save button when data has been loaded and object has changed
    useEffect(() => {
        if ((initialState?.name !== orgInfo?.name || initialState?.address !== orgInfo?.address)) {
            setIsDisabled(false)
        } else setIsDisabled(true)
    }, [initialState, orgInfo])

    const handleSave = () => {
        // TODO: make name unique
        setIsSaving(true)
        updateOrgDetails(orgInfo)
            .then((res) => {
                setAlert({
                    show: true,
                    variant: 'success',
                    message: 'Your info has been updated.'
                })
                setIsSaving(false)
                // save initial state to check whether to disable button
                // setInitialState(res.data)
                setIsDisabled(true)

            })
            .catch((err) => {
                setIsSaving(false)
                console.error(err)
                setAlert({
                    show: true,
                    variant: 'danger',
                    message: 'Unable to save info please try again.'
                })
            })
    }

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <div className="position-relative">
                    <section className={`wrapper ${!hasPermission ? 'overlay' : ''}`}>
                        {alert.show &&
                            <>
                                <Alert variant={alert.variant} className="mb-5" onClose={() => setAlert({ show: false, variant: '', message: '' })} dismissible>
                                    {alert.message}
                                </Alert>
                            </>
                        }
                        <header className="section-header">
                            <div className="section-heading section-heading--secondary">
                                <h1>Organization info</h1>
                            </div>
                            <p className='section-header-desc'>Details that apply across your events and billing</p>
                        </header>
                        <Card body className='card--sm'>
                            <OrganizationInformationWrapper getOrgInfo={setOrgInfo} orgInfo={orgInfo} />
                        </Card>
                        <Stack direction="horizontal" className="btn-group-flex">
                            <Button size="lg" className="btn-width-sm" disabled={isDisabled || isSaving} onClick={handleSave}>
                                {isSaving ? (
                                    <Spinner />
                                ) :
                                    "Save"
                                }
                            </Button>
                        </Stack>
                    </section>
                    {!hasPermission && (
                        <NoPermissionsContainer />
                    )}
                </div>
            )}
        </>
    );
}
