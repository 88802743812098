import React, { useEffect, useState } from "react";
import moment from 'moment'

import { capitalizeString, formatCurrency, formatDateTime, formatPhoneNumber, getTicket, getFees } from "../../../../utilities/helpers";

import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";

import { EmptyContainer } from "../../../EmptyContainer";

export default function OrdersTable({ orders, columns, selectedColumns, selectedTypes }) {

    const [tableColumns, setTableColumns] = useState([])

    const [tableRows, setTableRows] = useState([])

    useEffect(() => {
        if (selectedColumns?.length > 0) setTableColumns(getColumns(columns, selectedColumns))
    }, [selectedColumns])

    useEffect(() => {
        if (tableColumns?.length > 0) {
            const tableRows = buildRows(orders)
            setTableRows(tableRows)
            // TODO: filter out rows that don't include ticket types 
            // if (selectedTypes.length > 0) {
            //     console.log('types')
            //     const transactionTypeCol = tableRows.filter(row => row.find(r => console.log(r, r.columnId == 8)))
            //     console.log(transactionTypeCol);
            //     const filteredRows = tableRows.filter(row => {
            //         // if row has transaction type of selected then show otherwise remove 
            //         return row.every(r => {
            //             if (r.columnId === 8) {
            //                 return r.value.includes(selectedTypes?.map(type => type))
            //             }
            //             else return false
            //         })
            //     })
            //     console.log(filteredRows);
            // } else {
            //     setTableRows(tableRows)
            // }
        }
    }, [orders, tableColumns, selectedTypes])

    // get columns user selected
    const getColumns = (columns, selectedColumns) => {
        let tableColumns = [];
        // get columns user selected - use column idx to find table column
        columns?.map(column => {
            if (selectedColumns?.includes(column.idx)) {
                tableColumns = [...tableColumns, column]
            }
        })

        return tableColumns
    }

    // build rows - get row data - each row is an array  
    const buildRows = (orders) => {
        let rows = [];
        let row;
        orders?.map(order => {
            const { orderId, createdAt, type, total, gross, users_permissions_user, intentDetails } = order;
            const { ticketCount } = order?.details;

            const { email, firstName, lastName, phoneNumber, } = users_permissions_user;

            row = [orderId, createdAt, 'Winter in the Carolinas', firstName, lastName, 'Lewisville', 'Dallas', '75057', 'Texas', email, phoneNumber, ticketCount, type, getTicket(order, 'name'), gross, 45, getFees(order, 'ticketFacilityFee'), getFees(order, 'paymentProcessingFee'), getFees(order, 'ticketFacilityFee'), getFees(order, 'taxPerTicket'), 'Attending', (total !== 0 ? intentDetails?.charges?.data[0]?.payment_method_details?.card?.brand : 'N/A'), (total !== 0 ? intentDetails?.charges?.data[0]?.payment_method_details?.card?.last4 : 'N/A')]

            rows = [...rows, row]
        })

        return getRows(rows)
    }

    // get rows 
    const getRows = (rows) => {
        let tableRows = [];
        let currRow = [];
        // for each row
        rows?.map(row => {
            currRow = [];
            // get the data for each column 
            tableColumns.map(column => {
                currRow = [...currRow, { columnId: column.idx, value: row[column.idx - 1] }] // each row is an array of objects - need column id to know what column when formatting value 
            })

            return tableRows = [...tableRows, currRow]
        })

        return tableRows;
    }

    const format = (value, index) => {
        let formattedValue;
        switch (index) {
            case 2:
                formattedValue = formatDateTime(moment(value))
                break;

            case 3:
            case 4:
            case 5:
            case 13:
            case 22:
                formattedValue = capitalizeString(value)
                break;

            case 11:
                formattedValue = formatPhoneNumber(value)
                break;

            case 15:
            case 16:
            case 17:
            case 18:
            case 19:
            case 20:
                formattedValue = formatCurrency(value)
                break;
            default:
                break
        }

        if (formattedValue) return formattedValue
        else return value
    }
    return (
        <>
            {orders?.length > 0 && tableColumns?.length > 0 ? (
                <div className="table-container table-container--full-width">
                    <div className="table-container--fixed-height">
                        <Table className="table--full-width">
                            <thead>
                                <tr>
                                    {tableColumns.map((col, index) => (
                                        <th key={col.idx} style={{ textAlign: col.idx == columns.length ? 'right' : '' }}>{col.label}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {tableRows.map((row, index) => (
                                    <tr key={index}>
                                        {row.map(r => (
                                            <td key={r.columnId} style={{ textAlign: r.columnId == columns.length ? 'right' : '', whiteSpace: r.value?.length > 30 ? 'normal' : '' }}>{format(r.value, r.columnId)}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            ) : (
                <>
                    {orders?.length > 0 && tableColumns?.length === 0 ? (
                        <Card body>
                            <EmptyContainer style="center lg">
                                <p>Configure columns to show data on this table</p>
                            </EmptyContainer>
                        </Card>
                    ) : (
                        <Card body>
                            <EmptyContainer style="center lg">
                                <p>No event selected. Select one or more events to show data reports</p>
                            </EmptyContainer>
                        </Card>
                    )
                    }
                </>
            )}
        </>
    )
}