import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import LoadingContext from '../../context/Loading/Loading';
import AuthService from '../../utilities/services/auth.service';
import UserContext from '../../context/User/User';

import { getEvent, addDetailsToEvent, upload } from '../../utilities/api';
import { checkPermission, b64toBlob, isEventPast } from '../../utilities/helpers';

import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

import { UploadEventImage } from './UploadEventImage';
import { CreateEventButtons } from '../CreateEventButtons';
import { NoPermissionsContainer } from '../NoPermissionsContainer';
import { PageLoadingContainer } from '../PageLoadingContainer';

export default function DetailsWrapper({ eventId }) {

    const navigate = useNavigate();

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const { orgPermissions } = useContext(UserContext)

    const { getPermissions } = AuthService;

    const [hasPermission, setHasPermission] = useState(true);

    const [selectedImage, setSelectedImage] = useState()

    // decouple description from event summary to be able to use event summary as flag when it comes to disabling button 
    const [description, setDescription] = useState('')

    const [initialState, setInitialState] = useState(false)

    const [event, setEvent] = useState()

    const [alert, setAlert] = useState({
        show: false,
        variant: '',
        message: ''
    })

    const [showFooter, setShowFooter] = useState(false)

    const [isSaving, setIsSaving] = useState(false)

    useEffect(() => {
        if (orgPermissions?.length > 0) setHasPermission(checkPermission(orgPermissions, getPermissions(), 3));

    }, [orgPermissions])

    useEffect(() => {
        showLoading();
        getEvent(eventId)
            .then((res) => {
                if (res.data) {
                    setEvent(res.data)
                    setSelectedImage(res?.data?.image?.url || undefined)
                    setDescription(res?.data?.summary || '')
                    // save initial state to check whether to show save buttons 
                    setInitialState({
                        selectedImage: res?.data?.image?.url || undefined,
                        description: res?.data?.summary || '',
                    })
                }
                hideLoading()
            })
            .catch((err) => {
                console.error(err)
                hideLoading()
            })
    }, [eventId])

    // show/hide footer 
    useEffect(() => {
        if (initialState?.selectedImage !== selectedImage || initialState?.description !== description) setShowFooter(true)

        else setShowFooter(false)

    }, [event, initialState, selectedImage, description])

    useEffect(() => {
        // Listens for image upload
    }, [selectedImage])

    const isReadOnly = () => {
        return isEventPast(event)
    }

    // allow events to have no image on edit but must have image when newly created 
    const checkDisabled = () => {
        if (selectedImage) {
            return false;
        } else {
            // if description is erased disable button 
            if (!description) return true;

            // editing event - if no current image but event had a summary and image before 
            if (event?.summary || event?.image?.id) {
                return false;
            }
            return true
        }
    }

    const handleSave = () => {
        setIsSaving(true)
        if (selectedImage && !selectedImage.includes('https:')) { // updating image
            const formData = new FormData();
            const ImageURL = selectedImage;
            // Split the base64 string in data and contentType
            const block = ImageURL.split(";");
            // Get the content type of the image
            const contentType = block[0].split(":")[1];// In this case "image/gif"
            // get the real base64 content of the file
            const realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."

            // Convert it to a blob to upload
            const blob = b64toBlob(realData, contentType);
            formData.append(`files`, blob);
            upload(formData)
                .then((res) => {
                    let data = {};
                    data['description'] = description || null;
                    data['eventUUID'] = eventId;
                    data['image'] = res?.data[0].id;

                    addDetailsToEvent({ data })
                        .then((res) => {
                            setIsSaving(false)
                            window.scroll(0, 0)
                            setAlert({
                                show: true,
                                variant: 'success',
                                message: 'Your details have been updated.'
                            })
                            // not editing event - event image and event summary variables are not defined until page is refreshed and event state is updated
                            if (!event.image && !event?.summary) {
                                navigate(`/myevent/${eventId}/tickets`)
                            }

                            // save initial state again when updating to not show footer 
                            // selected image comes from api response and description stays the same as it comes from state
                            setSelectedImage(res?.data?.image?.url)
                            setInitialState({
                                selectedImage: res?.data?.image?.url || undefined,
                                description,
                            })
                        })
                        .catch((err) => {
                            console.error(err)
                            window.scrollTo(0, 0)
                            setIsSaving(false)
                            setAlert({
                                show: true,
                                variant: 'danger',
                                message: 'Unable to save details please try again.'
                            })
                        })
                })
                .catch((err) => {
                    console.error(err)
                    setIsSaving(false)
                    window.scrollTo(0, 0)
                    setAlert({
                        show: true,
                        variant: 'danger',
                        message: 'Unable to save details please try again.'
                    })
                })
        } else {
            let data = {};
            data['description'] = description || null;
            data['eventUUID'] = eventId;
            data['image'] = selectedImage ? event?.image?.id : null; // if image not removed, take existing image or null 

            addDetailsToEvent({ data }) // updating event desciption not image 
                .then((res) => {
                    setIsSaving(false)
                    window.scrollTo(0, 0)
                    setAlert({
                        show: true,
                        variant: 'success',
                        message: 'Your details have been updated.'
                    })

                    // save initial state again when updating to not show footer 
                    // selected image and description stay the same as they come from state 
                    setInitialState({
                        selectedImage,
                        description,
                    })
                })
                .catch((err) => {
                    console.error(err)
                    setIsSaving(false)
                    window.scrollTo(0, 0)
                    setAlert({
                        show: true,
                        variant: 'danger',
                        message: 'Unable to save details please try again.'
                    })
                })
        }
    }

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <div className='position-relative'>
                    <section className={`wrapper event-form ${!hasPermission ? 'overlay' : ''}`}>
                        {alert.show &&
                            <>
                                <Alert variant={alert.variant} className="mb-5" onClose={() => setAlert({ show: false, variant: '', message: '' })} dismissible>
                                    <p>{alert.message}</p>
                                </Alert>
                            </>
                        }
                        <section>
                            <header className="section-header-sm section-heading section-heading--secondary">
                                <h1>Main event image</h1>
                            </header>
                            <Card body className='card--sm'>
                                <UploadEventImage setSelectedImage={setSelectedImage} isDisabled={isReadOnly()} selectedImage={selectedImage} event={event} />
                            </Card>
                        </section>
                        <section>
                            <header className="section-header-sm section-heading section-heading--secondary">
                                <h1>Event description</h1>
                            </header>
                            <Card body className='card--sm'>
                                <Form.Control
                                    as="textarea" rows={5}
                                    name="description"
                                    aria-label='event-description'
                                    readOnly={isReadOnly()}
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </Card>
                        </section>
                    </section>
                    {showFooter && (
                        <CreateEventButtons isEditing={event?.image?.id || event?.summary ? true : false} isDisabled={checkDisabled() || isSaving} isSaving={isSaving} handleSave={handleSave} styles={`${!hasPermission ? 'overlay' : ''} `} />
                    )}
                    {!hasPermission && (
                        <NoPermissionsContainer />
                    )}
                </div>
            )}
        </>
    );
}
