import { useParams, useSearchParams } from "react-router-dom";

import { DailyTicketCountsWrapper } from "../../components";

export default function DailyTicketCountsPage() {

    const { uuid } = useParams();

    // get query from URL
    const [searchParams] = useSearchParams();

    const id = searchParams.get("id");

    return (
        <DailyTicketCountsWrapper eventId={uuid} id={id} />
    )
}