import React from "react";
import ReactPaginate from 'react-paginate';

import chevron from '../../../../assets/icons/chevron.svg'

import './pagination.scss';

export default function Pagination({ pageCount, handleClick }) {

    return (
        <>
            <ReactPaginate
                breakLabel="..."
                pageRangeDisplayed={3}
                nextLabel={<img src={chevron} alt="" />}
                previousLabel={<img src={chevron} alt="" />}
                pageCount={pageCount}
                onPageChange={handleClick}
                renderOnZeroPageCount={null}
                containerClassName="pagination-container"
                pageClassName="page-item"
                pageLinkClassName="page-item__link"
                activeClassName="page-item-active"
                activeLinkClassName="page-item__link-active"
                nextClassName="navigation-button"
                previousClassName="navigation-button"
                nextLinkClassName="navigation-button__link next"
                previousLinkClassName="navigation-button__link prev"
                disabledLinkClassName="navigation-button__link-disabled"
            />
        </>
    )
}