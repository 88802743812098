import React, { useState, useEffect, useContext } from 'react';

import LoadingContext from '../../context/Loading/Loading';

import {
    getOrganizationRoles, getOrganizationPermissions,
    getTeam
} from '../../utilities/api'
import { formatPermissions, formatMembers } from '../../utilities/helpers'

import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

import { TeamRoleWrapper } from "../TeamRoleWrapper";
import { PageLoadingContainer } from '../PageLoadingContainer';

export default function TeamManagementWrapper() {

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const [
        key,
        setKey
    ] = useState('roles');

    const [permissions, setPermissions] = useState([])

    const [roles, setRoles] = useState([])

    const [members, setMembers] = useState([])

    useEffect(() => {
        getRolesAndPermissions()
    }, [])

    const getRolesAndPermissions = () => {
        showLoading()
        getOrganizationRoles()
            .then((res) => {
                setRoles(res.data)

                getOrganizationPermissions()
                    .then((res) => setPermissions(formatPermissions(res.data.data)))
                    .catch((err) => {
                        console.error(err)
                        hideLoading()
                    })

                getTeam()
                    .then((res) => {
                        setMembers(formatMembers(res.data?.members))
                        hideLoading()
                    })
                    .catch((err) => {
                        console.error(err)
                        hideLoading()
                    })
            })
            .catch((err) => {
                console.error(err)
                hideLoading()
            })
    }

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <>
                    <section className='wrapper'>
                        <header className="section-header">
                            <div className="section-heading section-heading--secondary">
                                <h1>Team management</h1>
                            </div>
                            <p className='section-header-desc'>Manage your team members and their roles</p>
                        </header>
                        <Card body className='card--sm'>
                            <Tab.Container defaultActiveKey={key} activeKey={key} onSelect={(k) => setKey(k)}>
                                <div className='d-flex mb-5 '>
                                    <Nav as="ul" variant="pills" justify>
                                        <Nav.Item as="li">
                                            <Nav.Link as="button" eventKey="roles">
                                                Roles
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Nav.Link as="button" eventKey="team">
                                                Team
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                                <Tab.Content>
                                    <Tab.Pane unmountOnExit={true} eventKey="roles">
                                        <TeamRoleWrapper showRoles={true} showTeam={false} roles={roles} updateRoles={setRoles} permissions={permissions} />
                                    </Tab.Pane>
                                    <Tab.Pane unmountOnExit={true} eventKey="team">
                                        <TeamRoleWrapper showRoles={false} showTeam={true} members={members} roles={roles} updateMembers={setMembers} />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </Card>
                    </section>
                </>
            )}
        </>
    )
}
