import React, { useState, useEffect, useContext } from 'react';

import LoadingContext from '../../context/Loading/Loading';

import { guestList, removeGuestList } from '../../utilities/api';

import { PageLoadingContainer } from '../PageLoadingContainer';

import { GuestList } from './GuestList'

export default function GuestListWrapper({ eventId }) {

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const [guests, setGuests] = useState([])

    const [isRemoving, setIsRemoving] = useState(false)

    useEffect(() => {
        showLoading()
        getGL()
    }, [])

    const getGL = () => {
        guestList(eventId)
            .then((res) => {
                setGuests(res.data)
                hideLoading()
            })
            .catch((err) => {
                console.error(err)
                hideLoading()
            })
    }

    const removeGuest = (id) => {
        return new Promise((resolve, reject) => {
            setIsRemoving(true)
            let data = {
                id,
                event: eventId
            }
            removeGuestList(data)
                .then((res) => {
                    getGL()
                    setIsRemoving(false)
                    resolve();
                })
                .catch((err) => {
                    console.error(err)
                    setIsRemoving(false)
                    reject();
                })
        })
    }

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <section className='max-width-wrapper'>
                    <header className='section-header'>
                        <div className="section-header" >
                            <div className="section-heading">
                                <h1>Guest list</h1>
                            </div>
                            <p className='section-header-desc'>Manually add your guests information for complimentary tickets</p>
                        </div>
                    </header>
                    <GuestList guests={guests} remove={removeGuest} isRemoving={isRemoving} />
                </section>
            )}
        </>
    );
}
