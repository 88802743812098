import React, { useState, useEffect, useContext } from 'react';

import LoadingContext from '../../context/Loading/Loading';
import AuthService from '../../utilities/services/auth.service';
import UserContext from '../../context/User/User';

import { getSales } from '../../utilities/api';
import { checkPermission } from '../../utilities/helpers';

import Card from 'react-bootstrap/Card';

import { PageLoadingContainer } from "./../PageLoadingContainer";
import { PrimaryTickets } from './PrimaryTickets';
import { EmptyContainer } from "./../EmptyContainer";
import { NoPermissionsContainer } from '../NoPermissionsContainer';

export default function PrimaryTicketsWrapper({ eventId }) {

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const { orgPermissions } = useContext(UserContext)

    const { getPermissions } = AuthService;

    const [hasPermission, setHasPermission] = useState(true);

    const [tickets, setTickets] = useState([])

    const [capacity, setCapacity] = useState(0)

    const [ticketsRemaining, setTicketsRemaining] = useState(0)

    useEffect(() => {
        if (orgPermissions?.length > 0) setHasPermission(checkPermission(orgPermissions, getPermissions(), 14));

    }, [orgPermissions])

    useEffect(() => {
        sales()
    }, [])

    const sales = () => {
        showLoading()
        getSales(eventId, 'primary')
            .then((res) => {
                console.log(res)
                setTickets(res.data.tickets)
                setCapacity(res.data.capacity)
                setTicketsRemaining(res.data.ticketsRemaining)
                hideLoading()
            })
            .catch((err) => {
                console.error(err)
                hideLoading()
            })
    }

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <div className='position-relative'>
                    <section className={`max-width-wrapper ${!hasPermission ? 'overlay' : ''}`}>
                        <header className='section-header'>
                            <div className="section-header" >
                                <div className="section-heading">
                                    <h1>Primary tickets</h1>
                                </div>
                                <p className='section-header-desc'>Report generated from when sales started</p>
                            </div>
                        </header>
                        {tickets?.length > 0 ? (
                            <PrimaryTickets tickets={tickets} capacity={capacity} ticketsRemaining={ticketsRemaining} />
                        ) : (
                            <Card body>
                                <EmptyContainer style=" center lg">
                                    <p>There are no primary tickets sold for this event.</p>
                                </EmptyContainer>
                            </Card>
                        )}
                    </section>

                    {!hasPermission && (
                        <NoPermissionsContainer />
                    )}
                </div>
            )}
        </>
    );
}
