import { useParams } from 'react-router-dom';

import { ResaleTicketsWrapper } from "../../components";

export default function ResaleTicketsPage() {

    const { uuid } = useParams()

    return (
        <ResaleTicketsWrapper eventId={uuid} />
    )
}