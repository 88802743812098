import React from 'react';
import Select, { components } from 'react-select';

import Form from 'react-bootstrap/Form'

import clearIcon from "../../../../../assets/icons/search-icon.svg"
import searchIcon from "../../../../../assets/icons/search-icon.svg"

// multi select control for contact attendees form
export default function MultiSelectControl({ options, sendTo, onChange, value, placeholder }) {

    const handleKeyDown = e => {
        // tab key is pressed, don't do anything
        if (e.key === 'Tab') e.preventDefault();
    }

    const Option = props => {
        return (
            <components.Option {...props}>
                <div style={{ display: "flex" }}>
                    <div style={{ display: "flex", flexDirection: 'row', justifyContent: "space-between" }}>
                        <Form.Check
                            inline
                            name="checkbox"
                            type="checkbox"
                            checked={props.isSelected}
                            className='fw-medium'
                            style={{ pointerEvents: 'none' }}
                            onChange={() => null}
                        />
                        <div className="select-label one">{props.data.label}</div>
                    </div>
                    <div className="select-label two">{props.data?.custom}</div>
                </div>
            </components.Option>
        );
    };

    const MultiValueLabel = props => {
        const { innerProps: { innerRef, ...innerProps } } = props;

        return (
            <div ref={innerRef} {...innerProps} className="react-select__multi-value__label">
                {props.data.chipLabel}
            </div>
        )
    };

    const Menu = props => {
        return (
            <components.Menu {...props}>
                <div>
                    <div className='selected-count'>{sendTo?.length} selected</div>
                    <div>{props.children}</div>
                </div>
            </components.Menu>
        )
    }

    const IndicatorSeparator = props => {
        return null;
    }

    const DropdownIndicator = props => {
        const { innerProps: { innerRef, ...innerProps } } = props;
        return sendTo?.length <= 0 && <img ref={innerRef} {...innerProps} src={searchIcon} alt="search icon" />
    }

    const ClearIndicator = props => {
        const { innerProps: { innerRef, ...innerProps } } = props;
        return <img ref={innerRef} {...innerProps} src={clearIcon} alt="clear icon" />
    }

    return (
        <div className="select">
            <Select
                closeMenuOnSelect={false}
                isMulti
                hideSelectedOptions={false}
                options={options}
                onChange={onChange}
                onKeyDown={handleKeyDown}
                value={value}
                placeholder={placeholder}
                components={{
                    MultiValueLabel,
                    Menu,
                    IndicatorSeparator,
                    DropdownIndicator,
                    ClearIndicator,
                    Option
                }}
                styles={{
                    indicatorsContainer: (baseStyles, _) => ({
                        ...baseStyles,
                        flex: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '48px'
                    }),
                    option: (baseStyles, { isFocused }) => ({
                        ...baseStyles,
                        color: "#23262F",
                        backgroundColor: isFocused ? '#3e8bf71a' : null
                    }),
                }}
                className="react-select" classNamePrefix="react-select" />
        </div>
    );
}
