import { useParams } from "react-router-dom";

import { GuestUploadWrapper } from "../../components";

export default function GuestUploadPage() {

    const { uuid } = useParams();

    return (
        <GuestUploadWrapper eventId={uuid} />
    )
}