import React, { useState, useEffect, useRef } from 'react';

import { getAllEventStats } from '../../utilities/api';

import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import { SearchBar } from '../SearchBar';
import { EventsList } from './EventsList';
import { Spinner } from '../LoadingContainer/Spinner';

import './homeWrapper.scss';

export default function HomeWrapper() {

    const prevEventStatus = useRef('published');

    const paginateRef = useRef(0);

    const COUNT_PER_PAGE = 5;

    const eventStatusOpt = [
        {
            label: 'Published',
            value: 'published'
        },
        {
            label: 'Draft',
            value: 'draft'
        },
        {
            label: 'Past',
            value: 'past'
        }
    ]

    // set when searching or switching event statuses - show spinner 
    const [isLoading, setIsLoading] = useState(false);

    // fetching more events 
    const [isDataLoading, setIsDataLoading] = useState(false);

    const [events, setEvents] = useState([])

    const [eventStatus, setEventStatus] = useState('published')

    const [start, setStart] = useState(0)

    // flag whether all events are displayed
    const [isAllEvents, setIsAllEvents] = useState(false)

    // search query
    const [
        query,
        setQuery
    ] = useState('');

    // switching event statuses
    useEffect(() => {
        reset()
    }, [eventStatus])

    // searching for event
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            // Make API request with the current value
            if (query) {
                reset()
            }
        }, 1000);

        // no query reset events 
        if (!query) reset()

        return () => clearTimeout(delayDebounceFn);
    }, [query])

    const reset = () => {
        // reset pagination start to 0 
        paginateRef.current = 0
        getEvents()
        setIsLoading(true)
    }

    const getEvents = () => {
        getAllEventStats(eventStatus, query, paginateRef.current)
            .then((res) => {
                // searching or switching event statuses or resetting pagination count 
                if (query || prevEventStatus.current !== eventStatus || paginateRef.current === 0) {
                    setEvents(res?.data)
                    setState(res?.data)
                } else {
                    // loading more, append new events to existing events
                    setEvents([...events, ...res?.data])
                    setState(res?.data)
                }
            })
            .catch((err) => {
                console.error(err)
                setIsLoading(false)
                setIsDataLoading(false)
            })
    }

    // shared actions
    const setState = (events) => {
        // if less than paginate count all events are already displayed
        if (events?.length < COUNT_PER_PAGE) {
            setIsAllEvents(true)
        } else {
            setIsAllEvents(false)
        }
        setIsLoading(false)
        setIsDataLoading(false)
        prevEventStatus.current = eventStatus
    }

    const getNext = () => {
        setIsDataLoading(true)
        paginateRef.current = paginateRef.current + COUNT_PER_PAGE
        getEvents()
    }

    const handleSelect = (val) => {
        setEventStatus(val)
    }

    return (
        <section>
            <header className='section-header heading--flex'>
                <div className="section-heading">
                    <h1>Events</h1>
                </div>
                <SearchBar getQuery={setQuery} placeholder="Search by name" />
                <FloatingLabel controlId="status" label="Event status">
                    <Form.Select name="status" value={eventStatus} onChange={(e) => handleSelect(e.target.value)}>
                        {eventStatusOpt.map((option, index) => (
                            <option key={index} value={option.value}>{option.label}</option>
                        ))}
                    </Form.Select>
                </FloatingLabel>
            </header>
            <EventsList isLoading={isLoading} events={events} eventStatus={eventStatus} />
            {(!isLoading && events?.length > 0 && !isAllEvents) && (
                <Stack direction="horizontal" className='justify-content-center'>
                    <Button
                        variant="outline-light"
                        className='btn-width btn-width-sm mt-3'
                        disabled={isDataLoading}
                        onClick={getNext}
                    >
                        {isDataLoading ? (
                            <Spinner variant="dark" />
                        ) :
                            'Load more'
                        }
                    </Button>
                </Stack>
            )}
        </section>
    );
}
