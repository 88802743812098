import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { isValidPhoneNumber } from 'react-phone-number-input';

import LoadingContext from '../../context/Loading/Loading';

import { emailPatternMatch } from '../../utilities/helpers';
import { createGuestList, getEventTicketTypes } from '../../utilities/api';

import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import { GuestInfo } from './GuestInfo';
import { Spinner } from '../LoadingContainer/Spinner'
import { PageLoadingContainer } from '../PageLoadingContainer';

export default function GuestInformationWrapper({ eventId }) {

    const navigate = useNavigate();

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const [ticketTypes, setTicketType] = useState()

    const [valid, setValid] = useState(false)

    const [isSaving, setIsSaving] = useState(false)

    // demo purposes - will come from database - display error is ticket quantity is more than event max ticket quantity 
    const maxTicketQuantity = 50;

    const [guest, setGuest] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        quantity: '',
        ticketType: ''
    })

    const [
        phoneNumber,
        setValue
    ] = useState();

    const [isQuantityValid, setIsQuantityValid] = useState(true)

    const [isValidNumber, setIsValidNumber] = useState(true)

    const [isValidEmail, setIsValidEmail] = useState(true)

    const [countryCode, setCountryCode] = useState('');

    // save phone number to guest object every time it changes
    useEffect(() => {
        if (phoneNumber) {
            setGuest({ ...guest, phoneNumber })
        }
    }, [phoneNumber])

    useEffect(() => {
        if (!isQuantityValid) {
            setIsQuantityValid(true)
        }
    }, [guest.quantity])

    useEffect(() => {
        if (!isValidNumber) {
            setIsValidNumber(true)
        }
    }, [phoneNumber])

    useEffect(() => {
        if (!isValidEmail) {
            setIsValidEmail(true)
        }
    }, [guest.email])

    useEffect(() => {
        if (guest.phoneNumber && guest.firstName && guest.lastName && guest.quantity && guest.ticketType && isQuantityValid && isValidNumber && guest.email && isValidEmail) return setValid(true)
        setValid(false)
    }, [guest.firstName, guest.lastName, guest.quantity, guest.ticketType, guest.phoneNumber, guest.email, isQuantityValid, isValidNumber, isValidEmail])

    useEffect(() => {
        axios
            .get(`https://api.ipdata.co?api-key=${process.env.REACT_APP_IP_DATA_API_KEY}`)
            .then((res) => setCountryCode(res.data.country_code));
    }, []);

    useEffect(() => {
        showLoading()
        getEventTicketTypes(eventId)
            .then((res) => {
                setTicketType(res.data)
                hideLoading()
            })
            .catch((err) => {
                console.log(err)
                hideLoading()
            })
    }, [])

    useEffect(() => {

    }, [ticketTypes])

    const validNumber = () => {
        return phoneNumber && isValidPhoneNumber(phoneNumber)
    }

    const handleValidNumber = () => {
        // check if valid 
        setIsValidNumber(validNumber(phoneNumber))
    }

    const validatePattern = (val) => {
        const isMatch = emailPatternMatch(val)
        setIsValidEmail(isMatch)
    }

    const validQuantity = e => {
        if (e.target.value > maxTicketQuantity) {
            setIsQuantityValid(false)
        }
    }

    const handleChange = e => {
        if (e.target) setGuest({ ...guest, [e.target.name]: e.target.value })
    }

    const handleSave = () => {
        guest['quantity'] = Number(guest.quantity)
        guest['event'] = eventId;

        if (validNumber() && isValidEmail) {
            setIsSaving(true)
            createGuestList(guest)
                .then(() => {
                    setIsSaving(false)

                    if (!isSaving) {
                        navigate(-1)
                    }
                })
                .catch((err) => {
                    setIsSaving(false)
                    console.error(err)
                })
        }
    }

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <section className='wrapper'>
                    <section>
                        <header className="section-header section-heading">
                            <h1>Guest information</h1>
                        </header>
                        <Card body>
                            <GuestInfo
                                ticketTypes={ticketTypes}
                                guest={guest}
                                phoneNumber={phoneNumber}
                                handleChange={handleChange}
                                validatePattern={validatePattern}
                                isValidEmail={isValidEmail}
                                setPhoneNumber={setValue}
                                handleValidNumber={handleValidNumber}
                                isValidPhoneNumber={isValidNumber}
                                isQuantityValid={isQuantityValid}
                                validQuantity={validQuantity}
                                countryCode={countryCode}
                            />
                        </Card>
                    </section>
                    <Stack direction="horizontal" className="btn-group-flex">
                        <Button variant="outline-light" size="lg" onClick={() => navigate(-1)}>Cancel</Button>
                        <Button size="lg" className="btn-width-md" onClick={handleSave} disabled={!valid || isSaving}>
                            {isSaving ? (
                                <Spinner />
                            ) :
                                "Add Guest"
                            }
                        </Button>
                    </Stack>
                </section>
            )}
        </>
    );
}
