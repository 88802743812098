import React from 'react'

import { capitalizeString } from '../../../../utilities/helpers';

import Stack from 'react-bootstrap/Stack';
import Badge from 'react-bootstrap/Badge'

import { EditDeleteDropdown } from '../../../EditDeleteDropdown';

export default function Member({ member, handleResend, handleShowAdd, handleShowDelete }) {
    return (
        <Stack direction="horizontal" as="li" className='list-item split-row'>
            <h2 className='normal m-0'>{capitalizeString(`${member?.firstName} ${member?.lastName}`)}</h2>
            <Stack direction='horizontal' gap={2}>
                <Badge bg={`${member?.role?.name === 'Account Owner' ? 'success' : 'default'}`} className={`text-uppercase ${member?.role?.name !== 'Account Owner' ? 'badge-outline badge-outline--dark' : ''}`}>{capitalizeString(member?.role?.name)}</Badge>
                {member.role?.name !== 'Account Owner' && (
                    <EditDeleteDropdown pending={member?.pending} handleResend={() => handleResend(member)} onClick={(e) => handleShowAdd(e, member)} handleShow={() => handleShowDelete(member)} />
                )}
            </Stack>
        </Stack>
    )
}
