import { useParams } from 'react-router-dom';

import { TrackingLinksWrapper } from "../../components";

export default function ViewTrackingLinksPage() {

    const { uuid } = useParams()

    return (
        <TrackingLinksWrapper eventId={uuid} />
    )
}