import React, { useState, useEffect } from 'react'
import { isEqual } from 'lodash'

import {
    removeTeamMember
} from '../../../utilities/api'
import { emailPatternMatch } from '../../../utilities/helpers'

import Stack from 'react-bootstrap/Stack'
import Button from 'react-bootstrap/Button'

import { Member } from './Member'
import { AddMemberModal } from './AddMemberModal'
import { DeleteModal } from '../../DeleteModal'
import { InvitationSentModal } from './InvitationSentModal'

export default function Team({ members, updateMembers, roles, inviteMember, hasPermission = true, emailVaid, isInviting }) {

    // new and existing role so objects match for controlled input fields 
    // also for setting the deleted member
    const [member, setMember] = useState({
        firstName: '',
        lastName: '',
        email: '',
        role: {
            id: ''
        }
    })

    // save member email for invitation modal because member state is cleared when closing the add modal 
    const [inviteEmail, setInviteEmail] = useState('')

    // set member id (uuid) as flag for editing member
    const [id, setId] = useState()

    const [initialEditState, setInitialEditState] = useState()

    const [showAdd, setShowAdd] = useState(false);

    const [showInviteSent, setShowInviteSent] = useState(false);

    const [showDelete, setShowDelete] = useState(false);

    const [isEmailValid, setIsEmailValid] = useState(true);

    const [emailErrorMsg, setEmailErrorMsg] = useState('')

    const [isRemoving, setIsRemoving] = useState(false)

    useEffect(() => {
        if (id) setInitialEditState(member)
    }, [id])

    useEffect(() => {
        setIsEmailValid(true)
        setEmailErrorMsg('')
    }, [member?.email])

    const handleMember = (e) => {
        e.target && setMember({ ...member, [e.target.name]: e.target.value })
    }

    const handleRole = (e) => {
        const { value } = e.target;
        setMember(prevState => ({
            ...prevState,
            role: {
                ...prevState.role,
                id: Number(value)
            }
        }))
    }

    const handleAdd = () => {
        const { firstName, lastName, email, uuid } = member;
        const { id } = member.role;
        // save initialState because it is gone when closing add modal 
        const initialState = initialEditState;
        inviteMember({ firstName, lastName, email, role: id, uuid }).then(() => {
            handleCloseAdd()
            // if email changed 
            if (initialState?.email !== email) {
                handleShowInviteSent(email)
            }
        })
    }

    const handleResend = (member) => {
        const { firstName, lastName, email } = member;
        const { id } = member.role;
        inviteMember({ firstName, lastName, email, role: id }).then(() => {
            handleShowInviteSent(email)
        })
    }
    // handle add and edit 
    const handleShowAdd = (_, member) => {
        setShowAdd(true)
        if (member) {
            setMember(member)
            setId(member?.uuid)
        }
    }

    const handleCloseAdd = () => {
        setShowAdd(false)
        // reset state
        setMember('');
        setId()
        setInitialEditState()
    }

    const handleShowInviteSent = (email) => {
        setInviteEmail(email)
        setShowInviteSent(true)
    }

    const handleCloseInviteSent = () => {
        setShowInviteSent(false)
        setInviteEmail('')
    }

    const handleShowDelete = (member) => {
        setShowDelete(true)
        setMember(member)
    }

    const handleCloseDelete = () => {
        setShowDelete(false)
        setMember('')
    }

    const handleIsEmailValid = (val) => {
        // need to pass val for real-time value not state value
        const isMatch = emailPatternMatch(val)
        // if email matches pattern 
        if (isMatch) {
            emailVaid({ email: val })
                .then(res => {
                    setIsEmailValid(true)
                })
                .catch(err => {
                    setIsEmailValid(false)
                    setEmailErrorMsg('Email is already in use by another user')
                })
        } else {
            setIsEmailValid(false)
            setEmailErrorMsg('Invalid email. Please enter a valid email address')
        }
    }

    const removeMember = () => {
        setIsRemoving(true)
        removeTeamMember(member)
            .then((res) => {
                let newMembers = members.filter(m => m.email != member?.email)
                updateMembers(newMembers)
                setIsRemoving(false)
                handleCloseDelete();
            })
            .catch((err) => {
                console.error(err)
                setIsRemoving(false)
            })
    }

    return (
        <>
            <div className='heading--flex mb-4' id="teams">
                <h1 className='normal'>Team</h1>
                <Button variant='outline-light' disabled={!hasPermission} className="btn-plus btn-plus--dark" onClick={handleShowAdd}>Invite team member</Button>
            </div>
            <section>
                <Stack as="ul" gap={2}>
                    {members.filter(member => !member.pending).map((member, index) => (
                        <Member key={index} member={member} handleShowAdd={handleShowAdd} handleShowDelete={handleShowDelete} />
                    ))}
                </Stack>
            </section>
            <section>
                {members?.filter(member => member.pending)?.length > 0 && (
                    <>
                        <h1 className='text-muted small'>Pending invitations</h1>
                        <Stack as="ul" gap={2}>
                            {members.filter(member => member.pending).map((member, index) => (
                                <Member key={index} member={member} handleResend={handleResend} handleShowAdd={handleShowAdd} handleShowDelete={handleShowDelete} />
                            ))}
                        </Stack>
                    </>
                )}
            </section>

            <AddMemberModal show={showAdd} handleClose={handleCloseAdd} roles={roles} id={id} member={member} handleMember={handleMember} handleRole={handleRole} handleAdd={handleAdd} handleIsEmailValid={handleIsEmailValid} isEmailValid={isEmailValid} emailErrorMsg={emailErrorMsg} isInviting={isInviting} isDisabled={isEqual(initialEditState, member) || !member?.firstName || !member?.lastName || !member?.email || !member?.role?.id || !isEmailValid || isInviting} />

            <DeleteModal entity="team member" show={showDelete} handleClose={handleCloseDelete} isRemoving={isRemoving} handleDelete={removeMember} />

            <InvitationSentModal show={showInviteSent} handleClose={handleCloseInviteSent} email={inviteEmail} />
        </>
    )
}
