import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

import LoadingContext from '../../context/Loading/Loading';

import { getAutomaticReports, removeAutomaticReport } from '../../utilities/api'

import { PageLoadingContainer } from '../PageLoadingContainer';
import { Reports } from './Reports'
import { UserInformationWrapper } from "./UserInformationWrapper"

export default function DailyTicketCountsWrapper({ eventId, id }) {

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const navigate = useNavigate()
    const location = useLocation();

    const [reports, setReports] = useState([])

    // selected report to edit 
    const [report, setReport] = useState()

    const [isRemoving, setIsRemoving] = useState(false)

    const getReports = () => {
        getAutomaticReports(eventId)
            .then((res) => {
                setReports(res?.data)
                hideLoading()
            })
            .catch((err) => {
                console.error(err)
                hideLoading()
            })
    }

    // on location change
    useEffect(() => {
        showLoading()
        getReports()
        if (!location.pathname.includes('edit')) setReport()

    }, [location])

    // get report on refresh if editing
    useEffect(() => {
        if (location.pathname.includes('edit') && !report) {
            setReport(reports.find(report => report.id === Number(id)))
        }
    }, [location, reports])

    // handle different views 
    const handleClick = (_, id, report) => {
        if (!id) {
            navigate('add')
        }
        else {
            setReport(report)
            navigate(`edit?id=${id}`)
        }
    }

    const removeReport = (id) => {
        return new Promise((resolve, reject) => {
            setIsRemoving(true)
            removeAutomaticReport(id)
                .then((res) => {
                    getReports()
                    setIsRemoving(false)
                    resolve();
                })
                .catch((err) => {
                    console.error(err)
                    setIsRemoving(false)
                    reject();
                })
        })
    }


    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <section className='max-width-wrapper'>
                    {(!location.pathname.includes('/add') && !location.pathname.includes('/edit')) ? (
                        <section>
                            <header className='section-header'>
                                <div className="section-header" >
                                    <div className="section-heading">
                                        <h1>Daily ticket counts</h1>
                                    </div>
                                    <p className='section-header-desc'>Let agents, managers, and whomever know how many tickets have been sold for your event</p>
                                </div>
                            </header>
                            <Reports reports={reports} handleClick={handleClick} isRemoving={isRemoving} removeReport={removeReport} />
                        </section>
                    ) : (
                        <UserInformationWrapper eventId={eventId} id={id} report={report} />
                    )}
                </section>
            )}
        </>
    );
}
