import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEqual } from 'lodash'
import moment from 'moment'

import LoadingContext from '../../context/Loading/Loading';

import { createTickets, updateTickets, getEvent, getTaxRates, ticketsByGroup } from '../../utilities/api';
import { noTZDate } from '../../utilities/helpers';

import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

import { CreateTicket } from './CreateTicket';
import { CreateEventButtons } from '../CreateEventButtons';
import { PageLoadingContainer } from "../PageLoadingContainer";

export default function CreateTicketWrapper({ eventId, type }) {
    const navigate = useNavigate();

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    // error flag for ticket name because setErrors is asynchronous 
    let nameUniqueError = false;

    const [
        key,
        setKey
    ] = useState('paid');

    const [initialState, setInitialState] = useState()

    const [salesStart, setSalesStart] = useState(new Date(moment('12:00 pm', 'h:mm a').format()));

    const [salesEnd, setSalesEnd] = useState(new Date());

    const [hasError, setHasError] = useState(false)

    const [errors, setErrors] = useState()

    const [event, setEvent] = useState();

    const [ticket, setTicket] = useState({
        hideTicket: false,
        hasSalesTax: true,
        lockTicket: false,
        accessCode: '',
        name: 'General Admission',
        description: '',
        quantity: '',
        price: '',
        fee: '',
        minResalePrice: '',
        maxResalePrice: '',
        minQuantity: '',
        maxQuantity: '',
        soldOut: false,
        salesEnded: false
    })

    const [originalQuantity, setOriginalQuantity] = useState();

    const [fees, setFees] = useState({
        primaryOver20: 0,
        primaryUnder20: 0,
        secondaryServiceFeeBuyer: 0,
        secondaryServiceFeeSeller: 0,
        stripeCharge: 0,
        stripeServicePecentage: 0
    })

    const [taxRates, setTaxRates] = useState({
        stateTaxRate: 0,
        localTaxRate: 0,
        combinedTaxRate: 0
    })

    const [address, setAddress] = useState()

    const [showFooter, setShowFooter] = useState(false)

    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        showLoading()
        if (!address) return;
        getTaxRates(address?.city, address?.state)
            .then((res) => {
                setTaxRates(res?.data?.sales_tax_rates[0])
            })
            .catch((err) => {
                console.error(err)
                hideLoading()
            })
    }, [address])

    useEffect(() => {
        if (type) {
            let currentEvent;
            getEvent(eventId)
                .then((res) => {
                    currentEvent = res.data;
                    setEvent(res.data)
                    setFees(res.data?.organization?.fee_structure)
                    setAddress(res.data.venue.address[0])
                })
                .then(() => {
                    ticketsByGroup(type, eventId)
                        .then((res) => {
                            const ticket = res.data;

                            if (!ticket) return
                            setTicket({
                                name: ticket?.name,
                                free: ticket?.free,
                                description: ticket?.description,
                                quantity: ticket?.quantity,
                                price: ticket?.cost,
                                fee: ticket?.fee,
                                minResalePrice: ticket?.minResalePrice,
                                maxResalePrice: ticket?.maxResalePrice,
                                minQuantity: ticket?.minimum_quantity,
                                maxQuantity: ticket?.maximum_quantity,
                                hideTicket: ticket?.hidden,
                                hasSalesTax: ticket?.hasSalesTax,
                                ticketGroup: ticket?.ticketGroup,
                                lockTicket: ticket?.locked,
                                accessCode: ticket?.unlockCode,
                                soldOut: ticket?.soldOut,
                                salesEnded: ticket?.salesEnded
                            })
                            setSalesStart(new Date(noTZDate(ticket?.sales_start, currentEvent?.timezone?.offset)))
                            setSalesEnd(new Date(noTZDate(ticket?.sales_end, currentEvent?.timezone?.offset)))

                            setOriginalQuantity(ticket?.quantity)

                            // save initial state again if editing to check whether to show save buttons
                            setInitialState({
                                ticket: {
                                    name: ticket?.name,
                                    free: ticket?.free,
                                    description: ticket?.description,
                                    quantity: ticket?.quantity,
                                    price: ticket?.cost,
                                    fee: ticket?.fee,
                                    minResalePrice: ticket?.minResalePrice,
                                    maxResalePrice: ticket?.maxResalePrice,
                                    minQuantity: ticket?.minimum_quantity,
                                    maxQuantity: ticket?.maximum_quantity,
                                    hideTicket: ticket?.hidden,
                                    hasSalesTax: ticket?.hasSalesTax,
                                    ticketGroup: ticket?.ticketGroup,
                                    accessCode: ticket?.unlockCode,
                                    lockTicket: ticket?.locked,
                                    soldOut: ticket?.soldOut,
                                    salesEnded: ticket?.salesEnded
                                },
                                salesStart: new Date(noTZDate(ticket?.sales_start, currentEvent?.timezone?.offset)),
                                salesEnd: new Date(noTZDate(ticket?.sales_end, currentEvent?.timezone?.offset)),
                                originalQuantity: ticket?.quantity,
                            })
                            hideLoading();
                        })
                        .catch((err) => {
                            console.error(err)
                            hideLoading()
                        })
                })
                .catch((err) => {
                    console.error(err)
                    hideLoading()
                })
        } else {
            getEvent(eventId)
                .then((res) => {
                    setEvent(res.data)
                    setAddress(res.data.venue.address[0])
                    setFees(res.data?.organization?.fee_structure)

                    setSalesStart(new Date(getSalesStartDate(res?.data?.createdAt)))
                    // set sales end to event end 
                    setSalesEnd(new Date(res?.data?.end))
                    //  save initial state to check whether to show save buttons
                    setInitialState({
                        ticket,
                        salesStart: new Date(getSalesStartDate(res?.data?.createdAt)),
                        salesEnd: new Date(res?.data?.end)
                    })
                    hideLoading()
                })
                .catch((err) => {
                    console.error(err)
                    hideLoading()
                })
        }
    }, [eventId, type])

    // scroll to top if ticket name is already created
    useEffect(() => {
        if (errors?.field === 'name') {
            window.scroll(0, 0)
        }
    }, [errors])

    // remove error when ticket fields change
    useEffect(() => {
        setErrors()
    }, [ticket.name, ticket.quantity, ticket.price, ticket.fee, ticket.minResalePrice, ticket.maxResalePrice, ticket.minQuantity, ticket.maxQuantity])

    // change key if ticket is free when editing ticket 
    useEffect(() => {
        if (ticket.free) setKey('free')
    }, [ticket])

    // show/hide footer using lodash 
    useEffect(() => {
        const paidTicketInputsCheck = initialState?.ticket.price !== (parseFloat(ticket.price) || '') || initialState?.ticket.fee !== (isNaN(parseFloat(ticket.fee)) ? '' : parseFloat(ticket.fee)) || initialState?.ticket.minResalePrice !== (parseFloat(ticket.minResalePrice) || '') || initialState?.ticket.maxResalePrice !== (parseFloat(ticket.maxResalePrice) || '')

        const freeTicketInputsCheck = initialState?.ticket.hasSalesTax !== ticket.hasSalesTax || initialState?.ticket.hideTicket !== ticket.hideTicket || initialState?.ticket.lockTicket !== ticket.lockTicket || (ticket.lockTicket ? initialState?.ticket.accessCode !== ticket.accessCode : false) || initialState?.ticket.name !== (ticket.name || '') || initialState?.ticket.description !== (ticket.description || '') || initialState?.ticket.quantity !== (isNaN(parseInt(ticket.quantity)) ? '' : parseInt(ticket.quantity)) || initialState?.ticket.minQuantity !== (parseInt(ticket.minQuantity) || '') || initialState?.ticket.maxQuantity !== (parseInt(ticket.maxQuantity) || '') || !isEqual(new Date(initialState?.salesStart), new Date(salesStart)) || !isEqual(new Date(initialState?.salesEnd), new Date(salesEnd))

        // if ticket is not free
        if (!ticket.free) {
            setShowFooter(freeTicketInputsCheck || paidTicketInputsCheck)
        }
        // ticket is free
        else {
            setShowFooter(freeTicketInputsCheck)
        }
    }, [event, initialState, type, ticket.hasSalesTax, ticket.hideTicket, ticket.lockTicket, ticket.accessCode, ticket.name, ticket.description, ticket.quantity, ticket.price, ticket.fee, ticket.price, ticket.minResalePrice,
        , ticket.maxResalePrice, ticket.minQuantity, ticket.maxQuantity, salesStart, salesEnd])

    // set sales start date to be when the event was created at 12 PM
    const getSalesStartDate = (date) => {
        // set sales start to when the event was created 
        const salesStartDateObj = moment(salesStart);

        // do changes on that object
        salesStartDateObj.set('year', moment(date).year());
        salesStartDateObj.set('month', moment(date).month());
        salesStartDateObj.set('date', moment(date).date());

        return salesStartDateObj
    }

    const isReadOnly = () => {
        const { salesEnded, soldOut } = ticket;
        return (salesEnded || soldOut);
    }

    const handleChange = (e, val = e.target.value) => {
        setTicket({ ...ticket, [e.target.name]: val })
    }

    // check whether ticket name is unique
    const checkUnique = () => {
        let allTickets;
        // all event tickets except for current ticket if editing 
        if (type) allTickets = event?.tickets?.filter(evtTicket => evtTicket?.name.trim() !== type.trim())
        else allTickets = event?.tickets;

        if (allTickets?.findIndex(evtTicket => evtTicket?.name.trim() === ticket.name.trim()) !== -1) {
            nameUniqueError = true
            setErrors({
                field: 'name',
                message: 'A ticket is already created with the same name. Please choose a different name'
            })
        }
    }
    const handleValid = (e) => {
        const { name, value } = e.target;
        let num;

        switch (name) {
            case 'price':
                if (ticket.minResalePrice && num > ticket.minResalePrice) {
                    setErrors({
                        field: name,
                        message: 'Price of ticket cannot be more than minimum resale price'
                    })
                }
                else if (ticket.maxResalePrice && num > ticket.maxResalePrice) {
                    setErrors({
                        field: name,
                        message: 'Price of ticket cannot be more than maximum resale price'
                    })
                }
                break;
            case 'quantity':
                if (ticket.minQuantity && num < ticket.minQuantity) {
                    setErrors({
                        field: name,
                        message: 'Available quantity cannot be less than minimum quantity'
                    })
                } else if (ticket.maxQuantity && num < ticket.maxQuantity) {
                    setErrors({
                        field: name,
                        message: 'Available quantity cannot be less than maximum quantity'
                    })
                }
                break;
            case 'minResalePrice':
                if (num < ticket.price) {
                    setErrors({
                        field: name,
                        message: 'Minimum resale price cannot be less than price of ticket'
                    })
                }
                else if (ticket.maxResalePrice) {
                    if (num > ticket.maxResalePrice) {
                        setErrors({
                            field: name,
                            message: 'Minimum resale price cannot be more than maximum resale price'
                        })
                    }
                }
                break;
            case 'maxResalePrice':
                if (num < ticket.minResalePrice) {
                    setErrors({
                        field: name,
                        message: 'Maximum resale price cannot be less than minimum resale price'
                    })
                } else if (num < ticket.price) {
                    setErrors({
                        field: name,
                        message: 'Maximum resale price cannot be less than price of ticket'
                    })
                }
                break;
            case 'minQuantity':
                if (ticket.quantity && num > ticket.quantity) {
                    setErrors({
                        field: name,
                        message: 'Minimum quantity cannot be more than available quantity'
                    })
                }
                else if (ticket.maxQuantity) {
                    if (num > ticket.maxQuantity) {
                        setErrors({
                            field: name,
                            message: 'Minimum quantity cannot be more than maximum quantity'
                        })
                    }
                }
                break;
            case 'maxQuantity':
                if (ticket.minQuantity && num < ticket.minQuantity) {
                    setErrors({
                        field: name,
                        message: 'Maximum quantity cannot be less than minimum quantity'
                    })
                }
                else if (ticket.quantity && num > ticket.quantity) {
                    setErrors({
                        field: name,
                        message: 'Maximum quantity cannot be more than available quantity'
                    })
                }
                break;

            default:
                break;
        }
    }

    const handleSave = () => {
        buildTickets(ticket, salesStart, salesEnd)
    }

    const buildTickets = (ticket, start, end) => {
        // checkUnique(); disabling to test if ticketGroup is working as expected as duplicat names are now permitted
        if (!errors && !nameUniqueError) {
            setIsSaving(true)
            const data = {};
            data['name'] = ticket.name;
            data['description'] = ticket.description;
            data['cost'] = key === "free" ? 0 : parseFloat(ticket.price);
            data['fee'] = key === "free" ? 0 : parseFloat(ticket.fee);
            data['minimum_quantity'] = Number(ticket.minQuantity);
            data['maximum_quantity'] = Number(ticket.maxQuantity);
            data['minResalePrice'] = parseFloat(ticket.minResalePrice);
            data['maxResalePrice'] = parseFloat(ticket.maxResalePrice);
            data['eventId'] = eventId;
            data['free'] = key === "free" ? true : false;
            data['generalAdmission'] = true;
            data['quantity'] = ticket.quantity;
            data['sales_start'] = moment(start).format();
            data['sales_end'] = moment(end).format();
            data['hidden'] = ticket.hideTicket;
            data['locked'] = ticket.lockTicket;
            data['unlockCode'] = ticket.accessCode;
            data['hasSalesTax'] = ticket.hasSalesTax;

            if (!type) {
                createTickets({ data })
                    .then((res) => {
                        setIsSaving(false)
                        navigate(`/myevent/${eventId}/tickets`)
                    })
                    .catch((err) => {
                        setIsSaving(false)
                        console.error(err)
                    })
            } else {
                data['type'] = type;
                data['ticketGroup'] = ticket?.ticketGroup;
                data['originalQuantity'] = originalQuantity;
                updateTickets({ data })
                    .then((res) => {
                        setIsSaving(false)
                        navigate(`/myevent/${eventId}/tickets`)
                    })
                    .catch((err) => {
                        setIsSaving(false)
                        if (err.response.status === 409) {
                            setErrors({
                                field: 'quantity',
                                message: err.response.data
                            })
                        }
                        console.error(err)
                    })
            }
        }
    }

    const checkDisabled = () => {
        const { hideTicket, lockTicket, accessCode, name, quantity, price, fee, minResalePrice, maxResalePrice, minQuantity, maxQuantity } = ticket;

        if (type && (hideTicket || !hideTicket)) return false
        if (type && lockTicket && accessCode) return false
        
        if (key === 'paid') {
            return hasError || errors || !name || !quantity || !price || !fee || !minResalePrice || !maxResalePrice || !minQuantity || !maxQuantity|| hideTicket
        } else {
            return hasError || errors || !name || !quantity || !minQuantity || !maxQuantity || hideTicket
        }
    }

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <section className='wrapper event-form'>
                    <header className="section-header-sm section-heading section-heading--secondary">
                        <h1>{type ? 'Edit' : 'Create a'} ticket</h1>
                    </header>
                    <Card body className='card--sm'>
                        <Tab.Container defaultActiveKey={key} activeKey={key} onSelect={(k) => setKey(k)}>
                            {!type && (
                                <div className='d-flex mb-5 '>
                                    <Nav as="ul" variant="pills" justify>
                                        <Nav.Item as="li">
                                            <Nav.Link as="button" eventKey="paid">
                                                Paid
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Nav.Link as="button" eventKey="free">
                                                Free
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                            )}
                            <Tab.Content>
                                <Tab.Pane eventKey="paid">
                                    <CreateTicket eventEnd={event?.end} type={key}
                                        isEdit={type} isReadOnly={isReadOnly()} handleValid={handleValid} handleChange={handleChange} ticket={ticket} setSalesStart={setSalesStart} salesStart={salesStart} setSalesEnd={setSalesEnd} salesEnd={salesEnd} setHasError={setHasError} hasError={hasError} errors={errors} fees={fees} taxRates={taxRates} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="free">
                                    <CreateTicket eventEnd={event?.end} type={key}
                                        isEdit={type} isReadOnly={isReadOnly()} handleValid={handleValid} handleChange={handleChange} ticket={ticket} setSalesStart={setSalesStart} salesStart={salesStart} setSalesEnd={setSalesEnd} salesEnd={salesEnd} setHasError={setHasError} hasError={hasError} errors={errors} fees={fees} taxRates={taxRates} />
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </Card>
                    {showFooter && (
                        <CreateEventButtons page="Create ticket" isEditing={type ? true : false} isDisabled={checkDisabled() || isSaving} isSaving={isSaving} handleSave={handleSave} />
                    )}
                </section>
            )}
        </>
    );
}
