import React, { useState, useEffect, useContext } from 'react';
import { Link } from "react-router-dom";

import AuthService from '../../../utilities/services/auth.service';
import UserContext from '../../../context/User/User';

import { checkPermission } from '../../../utilities/helpers';

import Stack from 'react-bootstrap/Stack';
import Card from 'react-bootstrap/Card'

import { Guest } from './Guest'
import { EmptyContainer } from '../../EmptyContainer';
import { DeleteModal } from '../../DeleteModal';

export default function GuestList({ guests, remove, isRemoving }) {

    const { orgPermissions } = useContext(UserContext)

    const { getPermissions } = AuthService;

    const [hasPermission, setHasPermission] = useState(true);

    const [show, setShow] = useState(false)

    // guest id to delete
    const [id, setId] = useState()

    useEffect(() => {
        if (orgPermissions?.length > 0) setHasPermission(checkPermission(orgPermissions, getPermissions(), 10));

    }, [orgPermissions])

    const handleShow = (id) => {
        setShow(true);
        setId(id)
    }

    const handleClose = () => {
        setShow(false);
        setId();
    }

    const handleDelete = () => {
        remove(id).then(() => {
            handleClose()
        })
    }

    return (
        <>
            <Stack direction='horizontal' className='mb-5'>
                <Link to='add' className={`btn btn-outline-light btn-lg btn-plus btn-plus--dark ms-auto ${!hasPermission ? 'btn-link-disabled' : ''} `}>Add guest</Link>
                <Link to='upload' className={`ms-3 btn btn-outline-light btn-lg btn-plus btn-plus--dark ${!hasPermission ? 'btn-link-disabled' : ''} `}>Upload CSV file</Link>
            </Stack>
            <Card body>
                {guests && guests.length > 0 ? (
                    <div className="list-table five-col" role="table">
                        <div className="flex-row list-table-header" role="rowgroup">
                            <div className='list-table-col list-table-col-header' role="columnheader">
                                <span>First name</span>
                            </div>
                            <div className='list-table-col list-table-col-header  ' role="columnheader">
                                <span>Last name</span>
                            </div>
                            <div className="list-table-col list-table-col-header" role="columnheader">
                                <span>Phone number</span>
                            </div>
                            <div className="list-table-col list-table-col-header" role="columnheader">
                                <span>Quantity</span>
                            </div>
                            <div className="list-table-col list-table-col-header" role="columnheader">
                                <span>Ticket type</span>
                            </div>
                        </div>
                        {guests.map((guest, index) => (
                            <Guest key={index} guest={guest} handleShow={handleShow} />
                        ))}
                    </div>
                ) : (
                    <EmptyContainer>
                        <p>No guest list created, click Add guests to create a list.</p>
                    </EmptyContainer>
                )}
            </Card>

            <DeleteModal entity="guest" show={show} handleClose={handleClose} isRemoving={isRemoving} handleDelete={handleDelete} />
        </>
    );
}
