import React, { useState, useEffect, useContext } from 'react';

import LoadingContext from '../../context/Loading/Loading';
import AuthService from '../../utilities/services/auth.service';
import UserContext from '../../context/User/User';

import { getSales } from '../../utilities/api';
import { checkPermission } from '../../utilities/helpers';

import Card from 'react-bootstrap/Card';

import { ResaleTickets } from './ResaleTickets';
import { EmptyContainer } from "./../EmptyContainer";
import { NoPermissionsContainer } from '../NoPermissionsContainer';
import { PageLoadingContainer } from '../PageLoadingContainer';

export default function ResaleTicketsWrapper({ eventId }) {

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const { orgPermissions } = useContext(UserContext)

    const { getPermissions } = AuthService;

    const [hasPermission, setHasPermission] = useState(true);

    const [orders, setOrders] = useState([])

    const [feeStructure, setFeeStructure] = useState({})

    useEffect(() => {
        if (orgPermissions?.length > 0) setHasPermission(checkPermission(orgPermissions, getPermissions(), 14));

    }, [orgPermissions])

    useEffect(() => {
        sales()
    }, [])

    const sales = () => {
        getSales(eventId, 'secondary')
            .then((res) => {
                setOrders(res.data?.orders)
                setFeeStructure(res.data?.feeStructure)
            })
            .catch((err) => {
                console.error(err)
            })
    }

    return (
        <> {isLoading ? (
            <PageLoadingContainer />
        ) : (
            <div className='position-relative'>
                <section className={`max-width-wrapper ${!hasPermission ? 'overlay' : ''}`}>
                    <header className='section-header'>
                        <div className="section-header" >
                            <div className="section-heading">
                                <h1>Resale tickets</h1>
                            </div>
                            <p className='section-header-desc'>Report generated from when sales started</p>
                        </div>
                    </header>
                    {orders?.length > 0 ? (
                        <ResaleTickets orders={orders} feeStructure={feeStructure} />
                    ) : (
                        <Card body>
                            <EmptyContainer style="center lg">
                                <p>There are no resale tickets sold for this event.</p>
                            </EmptyContainer>
                        </Card>
                    )}
                </section>

                {!hasPermission && (
                    <NoPermissionsContainer />
                )}
            </div>
        )}
        </>
    );
}
