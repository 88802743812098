import React, { useState } from 'react';

import Modal from 'react-bootstrap/Modal';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import { BankAccountDetailsWrapper } from '../../BankAccountDetailsWrapper'
import { Spinner } from '../../LoadingContainer/Spinner';

import './bankAccountDetails.scss';

export default function BankAccountDetailsModal({ show, handleClose, getBankAccount, account, submit, isSaving }) {

    const [isValid, setIsValid] = useState(true);

    return (
        <Modal id="bank-account" centered animation={false} backdrop="static" fullscreen="md-down" scrollable show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title as="h4">Bank information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <BankAccountDetailsWrapper getBankAccount={getBankAccount} account={account} getIsValid={setIsValid} />
                <Stack direction="horizontal" className="btn-group-flex">
                    <Button size="lg" disabled={!isValid || isSaving} onClick={submit}>
                        {isSaving ? (
                            <Spinner />
                        ) :
                            <>
                                {account ? "Save details" : "Link bank account"
                                }
                            </>
                        }
                    </Button>
                </Stack>
            </Modal.Body>
        </Modal>

    );
}
