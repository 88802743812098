import { useParams, useSearchParams } from "react-router-dom";

import { ContactAttendeesWrapper } from "../../components";

export default function ContactAttendeesPage() {

    const { uuid } = useParams();

    // get query from URL
    const [searchParams] = useSearchParams();

    const keyType = searchParams.get('msg') ? searchParams.get('msg') : 'scheduled';

    const id = searchParams.get("id");

    return (
        <ContactAttendeesWrapper eventId={uuid} type={keyType} id={id} />
    )
}