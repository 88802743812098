import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import LoadingContext from '../../context/Loading/Loading';
import AuthService from '../../utilities/services/auth.service';
import UserContext from '../../context/User/User';

import { getTicketDetails, getEvent, removeTicketGroup } from '../../utilities/api';
import { checkPermission } from '../../utilities/helpers';

import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

import { Tickets } from './Tickets';
import { NoPermissionsContainer } from '../NoPermissionsContainer';
import { PageLoadingContainer } from '../PageLoadingContainer';

export default function TicketsWrapper({ eventId }) {

    const navigate = useNavigate();

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const { orgPermissions } = useContext(UserContext)

    const { getPermissions } = AuthService;

    const [hasPermission, setHasPermission] = useState(true);

    const [eventStatus, setEventStatus] = useState();

    const [tickets, setTickets] = useState()

    const [isRemoving, setIsRemoving] = useState(false)

    useEffect(() => {
        if (orgPermissions?.length > 0) setHasPermission(checkPermission(orgPermissions, getPermissions(), 3));
    }, [orgPermissions])

    useEffect(() => {
        showLoading();
        loadData()
    }, [eventId])

    const loadData = () => {
        getEvent(eventId)
            .then((res) => {
                setEventStatus(res.data.status)

                getTicketDetails(eventId)
                    .then((res) => {
                        setTickets(res.data)
                        hideLoading()
                    })
                    .catch((err) => {
                        console.error(err)
                        hideLoading()
                    })
            })
            .catch((err) => {
                console.error(err)
                hideLoading()
            })
    }

    const removeTickets = (id) => {
        return new Promise((resolve, reject) => {
            setIsRemoving(true)
            removeTicketGroup(id)
                .then((res) => {
                    loadData();
                    setIsRemoving(false)
                    resolve()
                })
                .catch((err) => {
                    setIsRemoving(false)
                    console.error(err)
                    reject();
                })
        })
    }

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <div className='position-relative'>
                    <section className={`max-width-wrapper event-form ${!hasPermission ? 'overlay' : ''}`}>
                        <header className="section-header-sm section-heading--flex section-heading section-heading--secondary">
                            <h1>Tickets</h1>
                            <Button variant="outline-light" disabled={eventStatus === "complete"} className='btn-plus btn-plus--dark' onClick={() => navigate('create')}>Add ticket</Button>
                        </header>
                        <Tickets tickets={tickets} removeTickets={removeTickets} isRemoving={isRemoving} />
                    </section>
                    {/* only show if event is not published yet */}
                    {eventStatus === "unpublished" && (
                        <div className="btn-footer">
                            <Stack direction="horizontal" className="btn-group-flex max-width-wrapper">
                                <Link to={`/myevent/${eventId}/publish`} className={`btn btn-primary btn-lg btn-next ${tickets?.length > 0 ? '' : 'btn-link-disabled'} ${!hasPermission ? 'overlay' : ''}`}>Publish</Link>
                            </Stack>
                        </div>
                    )}

                    {!hasPermission && (
                        <NoPermissionsContainer />
                    )}
                </div>
            )}
        </>
    );
}
