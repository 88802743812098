import React from 'react';

import { formatAddress } from '../../../utilities/helpers';

import Form from 'react-bootstrap/Form';

export default function Location({ event, isReadOnly, handleChange, venues }) {
    return (
        <Form>
            <Form.Group className="form-group" controlId="venue">
                <Form.Label>Venue</Form.Label>
                <Form.Select
                    name="venue"
                    disabled={isReadOnly}
                    value={event?.venue?.id || event.venue}
                    onChange={handleChange}
                >
                    <option key="-1">Select venue</option>
                    {venues && venues.map((option, index) => (
                        <option key={index} value={option?.id}>{option?.name} - {formatAddress(option)}</option>
                    ))}
                </Form.Select>
            </Form.Group>
        </Form>
    );
}