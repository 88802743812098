import React, { useState, useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { getEvent } from '../../utilities/api';

import { PageLoadingContainer, Sidenav } from '../../components';

export default function DashboardPage() {

    const { uuid } = useParams()

    const [isLoading, setIsLoading] = useState(false);

    const [event, setEvent] = useState()

    useEffect(() => {
        setIsLoading(true)
        getEvent(uuid)
            .then((res) => {
                setEvent(res.data)
                setIsLoading(false);
            }).catch((err) => {
                console.log(err)
                setIsLoading(false)
            })
    }, [uuid])

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer style="without-sidenav" />
            ) : (
                <>
                    <Sidenav event={event} />
                    <div className='spacer-md' id="main-content">
                        <Outlet />
                    </div>
                </>
            )}
        </>

    )
}