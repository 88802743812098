import React from 'react';
import moment from 'moment'

import { formatDateTime, formatShortAddress, getCopyrightYear } from '../../../../../utilities/helpers';

import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import CloseButton from 'react-bootstrap/CloseButton';
import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';

import logo from '../../../../../assets/client-logo.svg'
import placeholder from '../../../../../assets/placeholder.png';
import facebook from '../../../../../assets/icons/facebook-filled.svg';
import instagram from '../../../../../assets/icons/instagram.svg';
import twitter from '../../../../../assets/icons/twitter-filled.svg';
import tiktok from '../../../../../assets/icons/tiktok-filled.svg';
import linkedin from '../../../../../assets/icons/linkedin.svg';

import './previewModal.scss';

export default function PreviewModal({ show, handleClose, event, title, message }) {
    return (
        <Modal id="preview-modal" centered animation={false} fullscreen="md-down" show={show} onHide={handleClose}>
            <div className="header">
                <Stack direction='horizontal' className="justify-content-end">
                    <CloseButton onClick={handleClose} />
                </Stack>
            </div>
            <Modal.Body>
                <Image
                    src={logo}
                    alt="Blocktickets"
                    width="210"
                    height="108"
                    className="m-auto"
                />
                <h2 className='fs-md my-4'>{title}</h2>
                <pre className="normal">{message}</pre>
                <Card body>
                    <Image
                        src={event?.image?.url || placeholder}
                        alt={event?.name}
                        className="event-image"
                        width="212"
                        height="213"
                    />
                    <ul className='w-100'>
                        <li>
                            <h4 className="event-name text-center mb-1 mt-3">{event?.name}</h4>
                            <Stack as="ul" gap={1} className="small align-items-center">
                                <li>
                                    {formatDateTime(moment(event?.start), 'dateOnly')}
                                    {event?.display_start_time && (
                                        <span className='time'>
                                            {formatDateTime(moment(event?.start), 'timeOnly')}
                                        </span>
                                    )}
                                </li>
                                <li>{event?.venue?.name}</li>
                                <li className='text-muted'>{formatShortAddress(event?.venue)}</li>
                            </Stack>
                        </li>
                    </ul>
                </Card>
                <p className='disclaimer'>Need help? Email <a href="mailto:help@blocktickets.xyz" target="_blank" rel="noopener">help@blocktickets.xyz</a> and we will get back to you shortly!</p>
                <div className="footer">
                    <Image
                        src={logo}
                        alt="Blocktickets"
                        width="190"
                        height="108"
                    />
                    <span className='fw-bold small text-dark'>Find us</span>
                    <Stack direction='horizontal' as="ul" gap={4}>
                        <li>
                            <a href="https://www.facebook.com/blocktickets.xyz" target="_blank">
                                <Image src={facebook} alt="facebook" />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/blockticketsnft/" target="_blank">
                                <Image src={instagram} alt="instagram" />
                            </a>
                        </li>
                        <li>
                            <a href="https://twitter.com/Blockticketsnft" target="_blank">
                                <Image src={twitter} alt="twitter" />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.tiktok.com/@blocktickets?is_from_webapp=1&sender_device=pc" target="_blank">
                                <Image src={tiktok} alt="tiktok" />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/company/blocktickets-xyz/" target="_blank">
                                <Image src={linkedin} alt="linkedin" />
                            </a>
                        </li>
                    </Stack>
                    <Stack direction='horizontal' as="ul" id="external-links">
                        <li>
                            <a href="https://blocktickets.xyz" target="_blank">Blocktickets</a>
                        </li>
                        <li>
                            <a href="https://blocktickets.xyz/about-us" target="_blank">About Us</a>
                        </li>
                        <li>
                            <a href="https://blocktickets.xyz/purchase-policy" target="_blank">Purchase Policy</a>
                        </li>
                        <li>
                            <a href="https://blocktickets.xyz/privacy-policy" target="_blank">Privacy Policy</a>
                        </li>
                    </Stack>
                    <div className="d-flex align-items-center mb-3 mt-2 small">
                        <p className="text-muted">&copy; {getCopyrightYear()} Blocktickets. All rights reserved</p>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
