import { useParams } from 'react-router-dom';

import { TicketsWrapper } from "../../components";

export default function ViewTicketsPage() {

    const { uuid } = useParams()

    return (
        <TicketsWrapper eventId={uuid} />
    )
}