import React, { useState, useEffect, useContext } from 'react';

import LoadingContext from '../../context/Loading/Loading';
import AuthService from '../../utilities/services/auth.service';
import UserContext from '../../context/User/User';

import { getOrders } from '../../utilities/api';
import { checkPermission } from "./../../utilities/helpers";

import Card from 'react-bootstrap/Card';

import { OrdersList } from './OrdersList';
import { NoPermissionsContainer } from '../NoPermissionsContainer';
import { EmptyContainer } from '../EmptyContainer';
import { PageLoadingContainer } from '../PageLoadingContainer';

export default function OrdersWrapper({ eventId }) {

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const { orgPermissions } = useContext(UserContext)

    const { getPermissions } = AuthService;

    const [hasPermission, setHasPermission] = useState(true);

    const [details, setDetails] = useState({
        grossSales: 0,
        attendeesCount: 0,
        count: 0,
        orders: []
    })

    useEffect(() => {
        if (orgPermissions?.length > 0) setHasPermission(checkPermission(orgPermissions, getPermissions(), 6));

    }, [orgPermissions])

    useEffect(() => {
        showLoading()
        const getData = setTimeout(() => {
            getAllOrders()
        }, 500)
        return () => clearTimeout(getData)
    }, [])

    const getAllOrders = () => {
        getOrders(eventId, '', 'all')
            .then((res) => {
                setDetails(res.data)
                hideLoading()
            })
            .catch((err) => {
                console.error(err)
                hideLoading()
            })
    }

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <div className='position-relative'>
                    <section className={`max-width-wrapper ${!hasPermission ? 'overlay' : ''}`}>
                        <header className='section-header'>
                            <div className="section-heading">
                                <h1>Orders</h1>
                            </div>
                            <p className='section-header-desc'>See all the orders, refunds and transfers for your event, including gross sales with fees</p>
                        </header>
                        <section>
                            {details.orders.length > 0 ? (
                                <OrdersList eventId={eventId} details={details} />
                            ) : (
                                <Card body>
                                    <EmptyContainer style="center lg">
                                        <p>There are no orders for this event.</p>
                                    </EmptyContainer>
                                </Card>
                            )}
                        </section>
                    </section>
                    {!hasPermission && (
                        <NoPermissionsContainer />
                    )}
                </div>
            )}
        </>
    );
}
