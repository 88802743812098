import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { isEqual } from 'lodash'

import LoadingContext from '../../context/Loading/Loading';
import AuthService from '../../utilities/services/auth.service';
import UserContext from '../../context/User/User';

import { getEvent, publishEvent } from '../../utilities/api';
import { checkPermission, noTZDate } from '../../utilities/helpers';

import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import { PageLoadingContainer } from '../PageLoadingContainer';
import { Spinner } from '../LoadingContainer/Spinner';
import { PublishEvent } from './PublishEvent';
import { NoPermissionsContainer } from '../NoPermissionsContainer';

export default function PublishWrapper({ eventId }) {

    const navigate = useNavigate();

    const { isLoading, showLoading, hideLoading } = useContext(LoadingContext)

    const { orgPermissions } = useContext(UserContext)

    const { getPermissions } = AuthService;

    const [hasPermission, setHasPermission] = useState(true);

    const [publishDate, setPublishDate] = useState();

    const [event, setEvent] = useState();

    const [eventStarted, setEventStarted] = useState(false)

    const [publishType, setPublishType] = useState(1);

    const [alert, setAlert] = useState({
        show: false,
        variant: '',
        message: ''
    })

    const [isSaving, setIsSaving] = useState(false)

    useEffect(() => {
        if (orgPermissions?.length > 0)
            setHasPermission(checkPermission(orgPermissions, getPermissions(), 3));

    }, [orgPermissions])

    useEffect(() => {
        showLoading()
        getEvent(eventId)
            .then((res) => {
                setEvent(res.data)
                if (res?.data?.scheduled) {
                    setPublishType(2)
                }
                hideLoading()
            })
            .catch((err) => {
                console.error(err)
                hideLoading()
            })
    }, [eventId])

    // if publish type is schedule and event is not scheduled first, set publish date 3 hours ahead of event start date
    useEffect(() => {
        if (publishType === 2) {
            if (!event?.scheduledTime) {
                const publishDate = moment().add(3, 'h')
                setPublishDate(new Date(publishDate))
            }
        }
    }, [event, publishType])

    // set publish date to scheduled date or event start date
    useEffect(() => {
        checkEventStart()
        if (event?.scheduled) {
            setPublishDate(new Date(noTZDate(event?.scheduledTime, event?.timezone?.offset)))
        } else {
            setPublishDate(new Date(noTZDate(event?.start, event?.timezone?.offset)))
        }
    }, [event])

    const checkEventStart = () => {
        setEventStarted(moment(event?.start).isBefore(moment()))
    }

    const publish = () => {
        setIsSaving(true)
        let data = {};
        data['publishType'] = publishType ? publishType : 1;
        data['publishDate'] = moment(publishDate).format();
        // Remove tickets so payload isn't too large
        delete event?.tickets;
        data['event'] = event;

        publishEvent({ data })
            .then((res) => {
                setIsSaving(false)

                if (!isSaving) {
                    navigate(`/myevent/${event.uuid}`)
                }

                if (publishType === 1) {
                    // refresh to reload sidenav date 
                    window.location.reload()
                }
            })
            .catch((err) => {
                setIsSaving(false)
                {
                    console.error(err)
                }
                setAlert({
                    show: true,
                    variant: 'danger',
                    message: 'Unable to save info please try again.'
                })
            })
    }

    return (
        <>
            {isLoading ? (
                <PageLoadingContainer />
            ) : (
                <div className='position-relative'>
                    <section className={`wrapper event-form ${!hasPermission ? 'overlay' : ''}`}>
                        {alert.show &&
                            <>
                                <Alert variant={alert.variant} className="mb-5" onClose={() => setAlert({ show: false, variant: '', message: '' })} dismissible>
                                    <p>{alert.message}</p>
                                </Alert>
                            </>
                        }
                        <header className="section-header-sm section-heading section-heading--secondary">
                            <h1>Publish event</h1>
                        </header>
                        <Card body className="card--sm">
                            <PublishEvent setDate={setPublishDate} date={publishDate} setPublishType={setPublishType} publishType={publishType} eventStatus={event?.status} eventStarted={eventStarted} event={event} />
                        </Card>
                    </section>
                    {(!eventStarted && event?.status !== 'on_sale') && (
                        <div className="btn-footer">
                            <Stack direction="horizontal" className="btn-group-flex wrapper">
                                <Button className={`btn-width-md ${!isSaving ? `btn-${publishType == '1' ? 'send' : 'schedule'}` : ''} ${!hasPermission ? 'overlay' : ''} `} size="lg" onClick={publish} disabled={(publishType === 2 && isEqual(new Date(noTZDate(event?.scheduledTime, event?.timezone?.offset)), publishDate)) || event?.tickets?.length === 0 || isSaving}>
                                    {isSaving ? (
                                        <Spinner />
                                    ) : (
                                        <>
                                            {publishType == '1' ? 'Publish' : 'Schedule'}
                                        </>
                                    )}
                                </Button>
                            </Stack>
                        </div>
                    )}

                    {!hasPermission && (
                        <NoPermissionsContainer />
                    )}
                </div>
            )}
        </>
    );
}
